import styled from "styled-components";

export const Container = styled.div`
  font-family: 'PoppinsRegular';
  color: rgb(23,64,111);
  `;

export const Title = styled.div`
  padding:150px 70px 30px 70px;
  font-weight:bold ;
  @media (max-width: 991px){
    padding: 110px 15% 30px 10% !important;
  }
  >h1{
    position: relative;
    font-size: 48px;
    line-height: 45px;
    @media (max-width: 991px){
      font-size: 32px !important;
    }
  }
  >p{
    font-size: 14px;
    font-family:PoppinsRegular;
    font-weight: 400;
    line-height: 16px;
    >.retour{
      color:inherit;
      font-weight: bold;
      text-decoration: none;
      &:hover{
        text-decoration: underline !important;
      }
    }
    >span{
      color:#20ACE3;
    }
    >img{
      width: 28px;
      padding: 7px 10px 0 2px;
      margin-bottom: 15px;
    }
    .img-accueil-ar{
      padding: 7px 2px 0 10px;
    }
  }

  >.prestation{
    @media (max-width: 583px) and (min-width: 425px){
      padding-top: 25px;
      @media (max-width:553px) {
        font-size: 34px !important;
      }
      margin-left: -30px;
    }
  }
`;

export const WhatIs = styled.div`
  padding:30px 15% 50px 15% ;
  line-height: 28px;
  text-align: center;
  @media (max-width: 991px){
    padding: 60px 10% 10px !important;
    text-align: left;
  }
  @media (max-width: 500px){
    padding: 60px 10% 0px 10% !important;
  }
  >h2{
    text-align: center;
    padding-bottom: 10px;
    @media (min-width:991px) {
      padding: 30px 0 10px 0;
    }
    @media (max-width:991px) {
      text-align: left;
    }
  }
  >p{
    padding-bottom: 0px;
    @media (max-width: 500px){
      margin-bottom: 0;
    }
  }
  >.link .joindre{
    text-align: center;
    border-radius: 15px;
    color: white !important;
    background-color: #20ACE3;
    font-family:PoppinsRegular;
    @media (max-width: 991px) {
      margin-left: 0;
    }
    @media (max-width:500px) {
      margin-top: 27px;
    }
  }
  >.joindre{
    text-align: center;
    border-radius: 15px;
    color: white !important;
    background-color: #20ACE3;
    font-family:PoppinsRegular;
  }
`;


