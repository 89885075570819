import React, { useEffect, useState } from "react";
import styled from "styled-components"
import Client from "../../Model/Client";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTypedCountry } from './useTypedCountry.tsx';
import { URL_Back, URL_Dev } from "./Variables";
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Pages from "../../Model/Pages";
import { Helmet } from 'react-helmet-async'
import i18next from "../../i18next";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";


const Home = (props) => {

  gsap.registerPlugin(ScrollTrigger);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
 
  const [url, setUrl] = useState("notre-solution-bowling");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const { t } = props
  let handler = props.handler;
  const Countries = [`${t('France')}`, `${t('Tunisia')}`]
  const Country = useTypedCountry(Countries)

  const responsive = {
    plasama: {
      breakpoint: { max: 3000, min: 2000 },
      items: 6
    },
    ldesktop: {
      breakpoint: { max: 2000, min: 1600 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1600, min: 1312 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1312, min: 950 },
      items: 3
    },
    mini: {
      breakpoint: { max: 950, min: 671 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 671, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    setLangue(currentLanguage)
    currentLanguage === 'fr' ? setUrl("notre-solution-bowling")
      : currentLanguage === "en"
        ? setUrl("our-bowling-solution")
        : setUrl("حل-البولينج-لدينا")
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname)

    Aos.init({ duration: 2000 });

    gsap.registerPlugin(ScrollTrigger);
    gsap.config({
      autoSleep: 60,
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
      units: { left: "%", top: "%", rotation: "rad" }
    });
    gsap.to(document.getElementsByClassName("down"), {
      scrollTrigger: {
        trigger: document.getElementsByClassName("images"),
        toggleActions: "restart pause reverse pause",
        start: "top center+=100px",
        end: "bottom center-=300px",
        scrub: true,
      },
      y: 100,
      x: -100,
      duration: 3,
    });

    gsap.to(document.getElementsByClassName("up"), {
      scrollTrigger: {
        trigger: document.getElementsByClassName("images"),
        toggleActions: "restart pause reverse pause",
        start: "top center+=100px",
        end: "bottom center-=300px",
        scrub: true,
      },
      y: -100,
      x: +100,
      duration: 3,
    });

    fetch(`${URL_Back}/wp-json/wp/v2/clients?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/images?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setImages(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let clients = [];
  let image = [];
  items.map(element =>
    clients.push(new Client(element.id, element.title.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)));
  images.map(element =>
    image.push(new Client(element.id, element.title.rendered, element.date, element._embedded['wp:featuredmedia'][0]))
  )
  let page = []
  pages.map(element =>
    element.slug === "home" &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = "Accueil de l'Oxton Digital pour développement web, mobile et borne"
  
  

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={`${URL_Dev}`} />
      </Helmet>
      <div className="container-fluid" >
        
        <Title>
          <h1>
            {t("We're Your")} <Creative>{t('Creative Agency')}</Creative> <br className="since2007" />  {t("Since")} 2007
          </h1>
        </Title>
       
       
        <Image>
          <Incroyable>
            <Link className="link" to={langue === "ar" ? "/ar/مَلَفّ" : `/${langue}/portfolio`}>
              <div className={langue === "ar" ? "incroyable incroyable-ar" : "incroyable"}>
                <p>
                  {langue === "ar"
                    ? t('Our_portfolio')
                    : t('Our')
                  }
                  {langue !== "ar"
                    && <br />
                  }
                  {langue !== "ar"
                    && t('portfolio')
                  }
                  <span className="plus">
                    +
                  </span>
                </p>
              </div>
            </Link>
          </Incroyable>
          <div className="images">
            <ul className="up">
              {image.map((item, key) => key < 4 && (
                <li key={item.id}><img src={item.image.source_url} alt={item.image.title.rendered} /></li>
              ))}
            </ul>
            <ul className="down">
              {image.map((item, key) => key > 3 && key < 9 && (
                <li key={item.id}><img src={item.image.source_url} alt={item.image.title.rendered} /></li>
              ))}
            </ul>
            <ul className="up">
              {image.map((item, key) => key > 8 && (
                <li key={item.id}><img src={item.image.source_url} alt={item.image.title.rendered} /></li>
              ))}
            </ul>
          </div>
        </Image>
        <Details data-aos="fade-up">
          <div>
            <h2>{t('Home.We transform')}</h2>
            <p>
              {t('Home.Are you looking for a web development agency')}<br />
              {t('Home.We are experts')}</p>
          </div>

        </Details>
        <NosServices>
          <Services >
            <h2>{t('Our_services')} </h2>
            <p>{t('Home.Oxton Digital offers')} </p>
            <Link to={langue === "fr" ? "/fr/nos-services" : langue === "en" ? "/en/web-development" : "/ar/تطوير-الويب"} >
              <button className={langue === "ar"? "btn btn-primary button button-ar" : "btn btn-primary button"} type="submit"><span className="pseudo-text">{t('Home.All our services')} </span></button>
            </Link>
          </Services>
          <Services>
            <Link to={langue === "fr" ? "/fr/nos-services" : langue === "en" ? "/en/web-development" : "/arتطوير-الويب"} className="links">
              <img src="/images/home/services/Group 1498.png" alt="Group 1498" /><br />
              <h3>{t('Design')}</h3>
              <p>{t('Home.We provide high quality design services')} </p>
            </Link>
          </Services>
          <Services>
            <Link to={langue === "fr" ? "/fr/nos-services" : langue === "en" ? "/en/web-development" : "/arتطوير-الويب"} className="links" >
              <img src="/images/home/services/code.svg" alt="code" /><br />
              <h3>{t('Development')}</h3>
              <p>{t('Home.Our Web and Mobile development services')}</p>
            </Link>
          </Services>
          <Services>
            <Link to={langue === "fr" ? "/fr/developpement-mobile" : langue === "en" ?  "/en/mobile-development" : "/ar/تطوير-المحمول"} className="links">
              <img src="/images/home/services/Group 1497.png" alt="Group 1497" /><br />
              <h3>{t('Mobile')}</h3>
              <p>{t('Home.We develop mobile applications')}</p>
            </Link>
          </Services>
          <Services>
            <Link to={langue === "fr" ? "/fr/search-engine-optimization" : langue === "en" ?  "/en/search-engine-optimization" : "/ar/محرك-البحث-الأمثل"} className="links">
             
              <img src="/images/home/services/Group 1500.png" alt="Group 1500" /><br />
              <h3>{t('Seo.Seo')}</h3>
              <p>{t('Home.Our SEO services')}</p>
            </Link>
          </Services>
          <Services>
            <Link to={langue === "fr" ? "/fr/service-de-consultation" : langue === "en" ? "/en/service-provision" : "/ar/تقديم-الخدمة"} className="links">
              
              <img src="/images/home/services/social-media-marketing.png" alt="social-media-marketing" /><br />
              <h3>{t('Marketing')}</h3>
              <p>{t('Home.We_can_help')} </p>
            </Link>
          </Services>
          <Services>
            <Link to={langue === "fr" ? "/fr/community-management" : langue === "en" ?  "/en/community-management" : "/ar/إدارة-المجتمع"} className="links">
              
              <img src="/images/home/services/Group 1499.png" alt="Group 1499" /><br />
              <h3>{t('SEA')} / {t('Ads')}</h3>
              <p>{t('Home.We offer advertising')} </p>
            </Link>
          </Services>
        </NosServices>
        <Background>
          <img src="/images/home/Plan de travail.jpg" alt="Plan de travail" />
        </Background>
        <Digibow data-aos="fade-up">
          <Detail>
            <Link to={langue === "fr" ? "/fr/notre-solution-bowling" : langue === "en" ? "/en/our-bowling-solution" : "/ar/حل-البولينج-لدينا"} className="links">
              <h2 className={langue === "ar" && "alignRightMobile"}>{t('Home.Our_Digibowl_Solution')} </h2>
            </Link>
            <h3>{t('Home.Digitization of the point of sale')} </h3>
            <p>{t('Home.A Web, Mobile and interactive kiosks solution')} </p>
          </Detail>
          <Detail>
            <p>{t('Home.Increase your turnover')}</p>
          </Detail>
        </Digibow>
        <Bowling>
          <div className="row gy-4">
            <div className="col-md-3">
              <Link className="link" to={`/${langue}/${url}/space-bowling`}>
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src="/images/home/cards/Group 38813.jpg" alt="Group 38813" />
                  </div>
                  <div className="flip-card-back">
                    <div className="content">
                      <img src="/images/home/cards/Mask Group 665.png" alt="Mask Group 665" />
                      <h4>{t('Space_Bowling')}</h4>
                      <p>{t('SolutionBowling.discover')}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link className="link" to={`/${langue}/${url}/extra-laser-bowling`}>
                <div className="flip-card-inner" >
                  <div className="flip-card-front">
                    <img src="/images/home/cards/Group 38836.jpg" alt="Groupe 38836" />
                  </div>
                  <div className="flip-card-back">
                    <div className="content">
                      <img src="/images/home/cards/Mask Group 662.png" alt="Mask Group 662" />
                      <h4>{t('Extra Lazerbowl')}</h4>
                      <p>{t('SolutionBowling.located')}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link className="link" to={`/${langue}/${url}/planet-bowling`}>
                <div className="flip-card-inner" >
                  <div className="flip-card-front">
                    <img src="/images/home/cards/Group 38815.jpg" alt="Groupe 38815" />
                  </div>
                  <div className="flip-card-back">
                    <div className="content">
                      <img src="/images/home/cards/Mask Group 661.png" alt="Mask Group 661" />
                      <h4>{t('Planet_bowling')}</h4>
                      <p>{t('SolutionBowling.largest')} </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link className="link" to={`/${langue}/${url}/bowling-de-limoges`}>
                <div className="flip-card-inner" >
                  <div className="flip-card-front">
                    <img src="/images/home/cards/Group 38814.jpg" alt="Group 38814" />
                  </div>
                  <div className="flip-card-back">
                    <div className="content">
                      <img src="/images/home/cards/Mask Group 664.png" alt="Mask Group 664" />
                      <h4>{t('Bowling_de_Limoges')}</h4>
                      <p>{t('SolutionBowling.Limoges')}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </Bowling>
        <NosClients data-aos="fade-up">
          <h2 className={langue === "ar" && "alignRightMobile"}>{t('Our clients')} </h2>
          <div className="listeOfClients">
            <Carousel responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              containerClass="carousel-container">
              {clients.map(item => (
                <img key={item.id} src={item.image} alt={item.title} className="img-fluid imgClients" />
              ))}
            </Carousel>
          </div>
        </NosClients>
      </div>
    </>)
}

const Title = styled.div`
  position: relative;
  font-weight:600;
  color:#17406F;
  padding:163px 70px 0 70px; 
  width: 95%;
  @media (max-width:991px) {
    padding:110px 40px 0 40px !important; 
  }
  
  >h1{
    font-size: 48px;
    @media (min-width: 991px) {
      line-height: 75px !important;
    }
    @media (max-width: 380px) {
      font-size: 28px !important;
    }
    @media (max-width: 776px) and (min-width: 380px){
      font-size: 32px !important;
    }
    >.since2007{
      display: none; 
      @media (max-width: 430px) {
      display: inline !important;
    }
    } 
  }
  >h1 span{
    text-decoration: underline;
    font-weight:600;
  }
`;

const Creative = styled.span`
  color: #20ACE3;
  text-decoration: none !important;
`;

const Incroyable = styled.div`
  >.link {
    >.incroyable{
      position: relative;
      left: 52.5%;
      top:320px;
      height: 400px;
      width: 350px;
      opacity: 90%;
      border-radius: 15px;
      z-index: 2;
      background-color:#20ACE3;
      >p{
        position: absolute;
        color: white;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        bottom: 10px;
        font-family: EurostileExtendedBold;
        font-size: 26px;
        line-height: 35px !important;
        opacity: 1;
        .plus{
          text-decoration: none;
          color: white;
          transition: transform 1050ms;
          font-size: 45px;
          font-weight: 600;
          font-family: Arial, Helvetica, sans-serif;
          &:hover{
            transform: rotate(360deg);
            }
        }
      }
      @media (max-width: 881px){
        left: 25%;
      }
      @media (max-width: 606px){
        width: 100%;
        left: 0 !important;
        right: 0 !important;
        top:120px;
        height: 120px;
      }
      @media (max-width: 606px){
        >p {font-size: 20px;}
      }
    }
    >.incroyable-ar{
      right:52.5%;
      left:auto;
      @media (max-width: 881px){
        right: 25%;
        left: auto;
      }

    }
  }
`;

const Image = styled.div`
  margin: -180px 70px 30px 70px;
  @media (max-width: 606px) {
    margin: -30px 40px 30px 40px;
  }
  @media (max-width: 674px) and (min-width: 607px){
    margin: -180px 40px 30px 40px;
  }
  .images{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
    padding-top: 90px;
    background-color: #f2f2f2;
    }
  
  .up,.down{
    position: relative;
    transform: rotate(45deg);
    padding-right: 30px;
    >li{ 
      padding: 15px;
      width: 450px;
      opacity: 0.8;
      list-style-type: none;
      @media (min-width: 1440px){
          margin:10px 10px;
          width: 600px;
        }
      @media (min-width: 1973px){
        margin:10px 40px;
        width: 800px;
      }
      >img{
        width: 80%;
      }
    }
  }
`;

const Details = styled.div`
  margin: 60px 140px 0px 140px;
  overflow: hidden;
  @media (max-width: 674px){
    margin: 60px 30px 30px 30px;
  }
  @media (max-width:799px) and (min-width: 674px) {
    margin: 60px 70px 30px 70px;
  }
  >div{
    padding: 24px;
    >.cercle{
      top: -40px;
      left: -72px;
      border-radius:50%;
      width: 220px;
      height: 220px;
      background-color: #ECF6FB;
    }
    >h2{
      padding-bottom: 23px;
    }
    >p{
      font-size:16px ;
      line-height: 30px;
    }
  }
`;

export const NosServices = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px 140px 80px 140px; 
  box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
  z-index: 0;
  @media (max-width:1000px) {
    margin: 30px 10% 80px;
  }
  @media (max-width: 830px){
    margin: 30px 70px 80px 70px;
  }
  @media (max-width: 674px){
    margin: 30px 40px 80px 40px;
  }
`;

const Services = styled.div`
  width: 25%;
  min-width: 220px;
  height: 302px;
  padding: 24px;
  border: 1px solid #F1F7FF;
  z-index: 99;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content:flex-end ;
  overflow:hidden ;
  @media (max-width:1962px)  and (min-width: 1379px){
    height: 324px;
  }
  @media (max-width:1118px)  and (min-width: 1379px){
    height: 344px;
  }
  @media (max-width:871px) and (min-width:711px){
    height: 326px;
  }
  @media (max-width: 752px) and (min-width:711px){
    height: 350px;
  }
  @media (max-width: 428px){
    height: 330px;
  }
  @media (max-width: 386px){
    height: 350px;
  }
  @media (max-width: 710px){
    width: 100%;
  }
  @media (max-width: 1378px) and (min-width: 711px){
    width: 50%;
  }
  &:hover{
      background-color: #F9FDFF; 
      }
  >.links{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    text-decoration: none;
    color: #17406F;
    &:hover{
      
      background-color: #F9FDFF; 
      @media (max-width:2308px){
       
      }
      @media (max-width:1962px) and (min-width: 1379px) {
        
      }
      @media (max-width:1674px) and (min-width: 1379px){
  
      }
      @media (max-width:1121px) and (min-width:711px){
     
      }

      @media (max-width:871px) and (min-width:711px){
        
      }
      @media (max-width: 752px) and (min-width:711px){
        
      }

      @media (max-width: 500px){
        
      }
      @media (max-width: 428px){
        
      }
      @media (max-width: 386px){
        
      }


      
      >p{
        height: 0;
       
      }
    }
    >img{
      width: fit-content;
      height: 40px;
    }
    >h3{
      margin-bottom:2px ;
    }
    >p{
        position:relative ;
        height:0 ;
        left:0;
        margin:0 ;
        font-size: 16px;
        
        line-height:26px!important;
    }
    .Arrow{
      
      position:relative ;
      top:-140px ;
      right:-280px ;
      width:50px ;
      height: fit-content;
      z-index:9 ;
      @media (max-width:1962px)  and (min-width: 1719px){
        top:-160px ;
      }
      @media (max-width:1718px)  and (min-width: 1379px){
        top:-160px ;
      }
      @media (max-width:876px){
        top:-160px ;
      }
    }
  }
  .links:hover > .Arrow{
    
    @media (max-width:1962px)  and (min-width: 1719px){
 
    }
    @media (max-width:1718px)  and (min-width: 1379px){
    
    }
    @media (max-width:876px){
     
    }
  }  
  
  &:first-child{
    width: 50%;
    min-width: 220px;
    height: auto !important;
    padding-right: 5%;
    padding: 30px;
    display: flex;
    justify-content: space-around;
    background-color: white !important;
    font-size:16px;
  @media (max-width: 991px) {
    padding-bottom: 20px;
  }
   
  @media (max-width: 369px){
    height: 390px;
    background-color: red;
  } 

  @media (max-width: 447px) and (min-width: 370px){
    height: auto;
  }
  @media (max-width: 1378px){
    width: 100%;
    }
  .button{
    width: 200px;
    font-size: 16px;
    margin-top: 20px;
  @media (max-width: 365px){
    width: 190px;
    }
  @media (max-width: 991px){
    margin-left: 0;
    }
  }
}`;

export const Background = styled.div`
  overflow: hidden;
  width: 100%;
  margin-top: -340px;
  >img{
    display: flex;
    width: 100%;
    height: 334px;
  }
`;

const Digibow = styled.div`
  display: flex;
  color: #17406F;
  font-size: 8px;
  margin: 30px 120px;
  @media (max-width: 675px){
    margin: 10px 30px 17px 30px;
    flex-direction: column;
   }
  @media (max-width: 810px) and (min-width: 676px){
    margin: 10px 70px 17px 70px;
    flex-direction: column;
  }
  @media (max-width: 1148px) and (min-width: 811px){
    margin: 10px 90px 17px 90px;
    flex-direction: column;
  }
`;

const Detail = styled.div`
  width: 50%;
  line-height: 28px;
  padding: 50px 20px 0 20px;
  @media (max-width:1148px) {
    padding: 0px 20px 0 20px;
    >a{
      color: #17406F;
      >h2{
      padding-top: 30px;
      }
    }
    >h3{
      padding-top: 10px;
    }
  }
  >a{
    color: #17406F;
  }
  >h3{
    font-size:16px !important;
  }
  >p{
    font-weight: normal;
    font-size:16px ;
    line-height: 30px;
    padding-bottom: 0px !important;
  }
  @media (max-width: 1148px){
    width: 100%;
   }

`;

export const Bowling = styled.div`
  padding: 30px 10% 60px 10%;
  @media (max-width:991px) {
    padding: 30px 10%;
  }
  
  >.row{
    >div {
      @media (max-width:624px) {
        width: 100%;
      }
      @media (max-width:1290px) and (min-width:625px){
        width: 50%;
      }
      padding-right: 20px;
      padding-left: 20px;
      >.link{
        &:hover .flip-card-inner { 
          transform: rotateY(180deg);
        }
        >.flip-card-inner {
          position: relative;
          height: 100%;
          width: 100%;
          transition: transform 0.8s;
          transform-style: preserve-3d;
          >.flip-card-front{
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            height: 100%;
            width: 100%;
            >img{
              position: relative;
              display: block;
              width: 100%;
              }
          }
          >.flip-card-back{
            position:absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transform: rotateY(180deg);
            >.content{
              text-decoration: none !important;
              color: #17406F;
              padding: 0 5%;
              background-color: white;
              border:1px solid #17406F;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              inset: 0;
              height: 100% !important;
              width: 100%;
              border-radius:8px;
              >img{
                width: 100%;
                padding-bottom:20px;
              }
            }  
          }
        }
      }
    }
  }
  
`;

const NosClients = styled.div`
  margin: 30px 60px 60px;
  @media (max-width:1100px) {
    margin: 30px 70px 60px;

  }
  @media (max-width:991px) {
    margin: 23px 70px 40px;
  }
  @media (max-width:425px) {
    margin: 23px 50px 40px;
  }
  >h2{
    text-align:center;
    padding-bottom: 40px;
  }
  >.listeOfClients >.carousel-container{
      padding: 0px !important;

      @media(max-width: 371px){
        padding-left: 45px ;
      }
      @media(max-width: 380px) and (min-width: 372px) {
        padding-left: 52px ;
      }
      @media(max-width: 384px) and (min-width: 381px) {
        padding-left: 55px ;
      }
      @media(max-width: 391px) and (min-width: 385px) {
        padding-left: 60px ;
      }
      @media(max-width: 400px) and (min-width: 391px) {
        padding-left: 65px ;
      }
      @media(max-width: 411px) and (min-width: 401px) {
        padding-left: 70px ;
      }
      @media(max-width: 420px) and (min-width: 411px) {
        padding-left: 75px ;
      }
      @media(max-width: 424px) and (min-width: 421px) {
        padding-left: 80px ;
      }
      @media(max-width: 440px) and (min-width: 425px) {
        padding-left: 60px ;
      }
      @media(max-width: 450px) and (min-width: 441px) {
        padding-left: 70px ;
      }
      @media(max-width: 457px) and (min-width: 451px){
        padding-left: 75px ;
      }
      @media(max-width: 471px) and (min-width: 458px){
        padding-left: 60px ;
      }
      @media(max-width: 480px) and (min-width: 472px){
        padding-left: 70px ;
      }
      @media(max-width: 500px) and (min-width: 481px){
        padding-left: 80px ;
      }
      @media(max-width: 520px) and (min-width: 501px){
        padding-left: 90px ;
      }
      @media(max-width: 550px) and (min-width: 521px){
        padding-left: 100px ;
      }
      @media(max-width: 600px) and (min-width: 551px){
        padding-left: 120px;
      }
      @media(max-width: 631px) and (min-width: 601px){
        padding-left: 140px;
      }
      @media(max-width: 671px) and (min-width: 632px){
        padding-left: 160px;
      }
      @media(max-width: 705px) and (min-width: 672px){
        padding-left: 35px;
      }
      @media(max-width: 720px) and (min-width: 706px){
        padding-left: 40px;
      }
      @media(max-width: 745px) and (min-width: 721px){
        padding-left: 45px;
      }
      @media(max-width: 760px) and (min-width: 746px){
        padding-left: 50px;
      }
      @media(max-width: 790px) and (min-width: 761px){
        padding-left: 55px;
      }
      @media(max-width: 820px) and (min-width: 791px){
        padding-left: 60px;
      }
      @media(max-width: 836px) and (min-width: 821px){
        padding-left: 70px;
      }
      @media(max-width: 890px) and (min-width: 837px){
        padding-left: 70px;
      }  
      @media(max-width: 926px) and (min-width: 891px){
        padding-left: 80px;
      }      
      @media(max-width: 950px) and (min-width: 927px){
        padding-left: 90px;
      }
      @media(max-width: 991px) and (min-width: 951px){
        padding-left: 40px;
      }
      @media(max-width: 1015px) and (min-width: 992px){
        padding-left: 35px;
      }
      @media(max-width: 1060px) and (min-width: 1016px){
        padding-left: 40px;
      }
      @media(max-width: 1080px) and (min-width: 1061px){
        padding-left: 45px;
      }
      @media(max-width: 1100px) and (min-width: 1081px){
        padding-left: 55px;
      }
      @media(max-width: 1140px) and (min-width: 1101px){
        padding-left: 60px;
      }
      @media(max-width: 1286px) and (min-width: 1141px){
        padding-left: 70px;
      }
      @media(max-width: 1312px) and (min-width: 1286px){
        padding-left: 90px;
      }
      @media(max-width: 1400px) and (min-width: 1312px){
        padding-left: 45px;
      }
      @media(max-width: 1500px) and (min-width: 1400px){
        padding-left: 60px;
      } 
      @media(max-width: 1600px) and (min-width: 1500px){
        padding-left: 70px;
      }  
      @media(max-width: 1910px) and (min-width: 1601px){
        padding-left: 40px;
      }  
      @media(max-width: 2271px) and (min-width: 1911px){
        padding-left: 60px;
      }
      @media(max-width: 2156px) and (min-width: 2271px){
        padding-left: 50px;
      }
      @media(max-width: 2297px) and (min-width: 2156px){
        padding-left: 60px;
      }
      @media(min-width: 2297px){
        padding-left: 80px;
    }
    
    .imgClients{
      max-width: 240px !important;
      max-height: 90px;
      @media (max-width:991px) {
        max-width: 200px !important;
      }
      @media (max-width:457px) {
        max-width: 170px !important;
      }

      @media (max-width:417px) and (min-width:375px) {
        max-width: 200px;
      }
    }  
  }

  .react-multi-carousel-track {
    align-items: center;
    text-align: center;
  }

  .react-multiple-carousel__arrow--left::before, .react-multiple-carousel__arrow--right::before {
    color: transparent;
  }
  .react-multiple-carousel__arrow{
    background: transparent;
  }
  .react-multiple-carousel__arrow--left::before {
    background: url('/images/home/Group 20204.svg') no-repeat;
    width: 32px;
    @media (max-width: 380px) {
      right: 25px;
    }
    @media (max-width: 671px)and (min-width: 381px){
      right: 15px;
    }
    @media (max-width: 837px)and (min-width: 672px){
      right: 48px;
    }
    @media (max-width: 1084px)and (min-width: 837px){
      right: 60px;
    }
    @media (max-width: 1244px)and (min-width: 1084px){
      right: 55px;
    }
    @media (max-width: 1582px) and (min-width: 1244px){
      right: 70px;
    }
    @media (max-width: 1832px) and (min-width: 1583px){
      right: 80px;
    }
    @media(min-width: 1833px){
      right:90px
    }
  }
  .react-multiple-carousel__arrow--right::before {
    background: url('/images/home/Group 20205.svg') no-repeat;
    left:30px;
    width: 30px;

    
    @media (max-width: 380px) {
      left: 25px;
    }
    @media (max-width: 671px)and (min-width: 381px){
      left: 15px;
    }
    @media (max-width: 692px) and (min-width: 672px){
      left: 55px;
    }
    @media (max-width: 953px) and (min-width: 693px){
      left: 45px;
    }
    @media (max-width: 1051px) and (min-width: 954px){
      left:68px;
    }
    @media (max-width: 1176px) and (min-width: 1051px){
      left: 65px;
    }
    @media (max-width: 1287px) and (min-width: 1177px){
      left: 75px;
    }
    @media (max-width: 1440px) and (min-width: 1287px){
      left: 80px;
    }
    @media(max-width: 2000px) and (min-width: 1441px){
      left: 87px;
    }
    @media(max-width: 2101px) and (min-width: 2001px){
      left: 107px;
    }
    @media(min-width: 2101px){
      left: 107px;
    }
  }
`;

export default withTranslation()(Home)
