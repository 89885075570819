import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Title, WhatIs } from "../../../css/commun";
import { Link, Redirect } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Prestation = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))
  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Title>
        <h1 className="prestation">
          {titre}
        </h1>
        <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <Link className="retour" to={`/${langue}`}>{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Title>
      <WhatIs className={langue === "ar" && "alignRightMobile"}>
        <h2 className={langue === "ar" ? "alignRightMobile prestation" : "prestation"}>
          {t('Prestation.development')}
        </h2>
        <p className="text">
          {t('Prestation.Benefit')}<br />
          {t('Prestation.Our_development')}
        </p>
        <a className="link" href="https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/" target="_blank"><button className={langue ? "btn btn-light button button-ar joindre" : "btn btn-light button joindre"}><span className="pseudo-text">{t('appointment')}</span></button></a>
      </WhatIs>
      <Externalisation>
        <div className="row">
          <div className="col-md-6">
            <div className={langue === "ar" ? "card card-ar" : "card"}>
              <img src="/images/prestation/Path 58702.svg" alt="Group 20132.svg" />
              <h3>Externalisation avec une ou plusieurs ressources dédiées</h3>
              <div className="card-body">
                <p>Il y a plusieurs avantages à externaliser en informatique, notamment :
                  <ul>
                    <li>Réduction des coûts : externaliser certaines tâches ou projets vous permettra de réduire vos coûts liés à la main-d'œuvre, aux équipements et à la formation.</li>
                    <li>Flexibilité : externaliser permet à nos clients de s'adapter facilement aux changements de demande en matière de compétences et/ou de projets.</li>
                    <li>Accès à des compétences spécialisées : en externalisant, votre entreprise aura accès à des compétences ciblées que vous n’avez pas en interne, comme des experts en cybersécurité ou en intelligence artificielle.</li>
                    <li>Amélioration de la qualité : nos équipes sont des experts dans leur domaine et offrent des services de qualité supérieure.</li>
                    <li>Meilleure utilisation des ressources internes : en externalisant certaines tâches, vous pourrez vous concentrer sur votre cœur de métier et utiliser de manière plus efficace les compétences de vos équipes.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={langue === "ar" ? "card card-ar" : "card"}>
              <img src="/images/prestation/code.svg" alt="Group 20132.svg" />
              <h3>Sous-traiter un projet IT avec Oxton Digital</h3>
              <div className="card-body">
                <p>Il y a plusieurs raisons pour lesquelles vous pouvez choisir de sous-traiter un projet IT, notamment :
                  <ul>
                    <li>Manque de compétences internes : certaines entreprises n’ont pas les compétences nécessaires pour réaliser un projet spécifique en interne, par exemple en matière de développement de logiciels ou de cybersécurité.</li>
                    <li>Contraintes de temps et de budget : les entreprises peuvent manquer de temps ou de budget pour réaliser un projet en interne, et choisissent de sous-traiter pour respecter les délais et les budgets impartis.</li>
                    <li>Meilleure utilisation des ressources internes : nos clients peuvent choisir de sous-traiter un projet pour pouvoir se concentrer sur leur cœur de métier et utiliser de manière plus efficace les compétences de leur personnel interne.</li>
                    <li>Accès à des compétences spécialisées : en sous-traitant un projet, vous accéderez à des compétences spécialisées que vous n’avez pas en interne, comme des experts en intelligence artificielle ou en blockchain.</li>
                    <li>Flexibilité : les entreprises s'adaptent facilement aux changements de demande en matière de compétences ou de projets en sous-traitant.</li>
                  </ul>
                  Vous choisirez donc de sous-traiter un projet IT pour des raisons économiques, de temps, de compétences techniques mais également pour une meilleure utilisation des ressources internes. Il est important de noter que la sous-traitance vous permettra aussi à être focus sur votre cœur de métier, sans être perturbé par des tâches qui ne sont pas liées à votre activité principale.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Externalisation>
      <Respect>
        <h2 className={langue === "ar" && "alignRightMobile"}>
          {t('Prestation.Respect')}
        </h2>
        <div className="row">
          <div className={langue === "ar" ? "col-md-6 left left-ar" : "col-md-6 left"}>
            {t('Prestation.your_project')}<br />
            {t('Prestation.deadlines')}
          </div>
          <div className="col-md-6 right">
            <ul className={langue === "ar" && "ul-ar"}>
              <li>
                {t('Prestation.Resource')}
              </li>
              <li>
                {t('Prestation.Training')}
              </li>
              <li>
                {t('Prestation.new_projects')}
              </li>
              <li>
                {t('Prestation.Expertise')}
              </li>
            </ul>
          </div>
        </div>

      </Respect>
      <VousAvez>
        <h3>
          {t('Prestation.IT_development')}
        </h3>
        <br />
        <Link className="link" to={langue === "fr" ? "/fr/contact" : langue === "en" ? "/en/contact-us" : "/ar/اتصل-بنا"}><button className={langue === "ar" ? "btn btn-light button button-ar" : "btn btn-light button"}><span className="pseudo-text">{t('appointment')}</span></button></Link>
      </VousAvez>
      <Aventage>
        <div className="row gy-4">
          <div className="col-md-6">
            <div className="card like">
              <div className="header Avantages">
                <img src="/images/prestation/Like_1_.svg" alt="Like_1.svg" />
                <h3>{t('Prestation.Benefits')}</h3>
              </div>
              <div className="card-body">
                <h4>{t('Prestation.Adaptability')} :</h4>
                <p>{t('Prestation.situation')}</p>
                <h4>{t('Prestation.Reduction')}  :</h4>
                <p>{t('Prestation.costs')}</p>
                <h4>{t('Prestation.Simplicity')} :</h4>
                <p>{t('Prestation.accounting')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card risque">
              <div className="header">
                <img src="/images/prestation/Path 58709.svg" alt="Path 58709" />
                <h3>{t('Prestation.Risks')}</h3>
              </div>
              <div className="card-body">
                <h4>{t('Prestation.served')} :</h4>
                <p>{t('Prestation.schedules')}</p>
                {/* les contrats dans l'ordre des commandes validées */}
                <h4>{t('Prestation.Loss')} :</h4>
                <p>{t('Prestation.assured')}</p>
                <h4>{t('Prestation.Dependence')} :</h4>
                <p>{t('Prestation.committed')}</p>
              </div>
            </div>
          </div>
        </div>
      </Aventage>
      <Partenaire>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Prestation.partner')}</h2>
        <div className="row gy-4 offre">
          <div className="col-lg-3">
            <div className="card">
              <img src="/images/prestation/euro-sign.svg" alt="euro-sign.svg" />
              <h3>{t('Prestation.Quality')}</h3>
              <p className="card-body">
                {t('Prestation.outsourcing')}
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card">
              <img src="/images/prestation/Path 58702.svg" alt="Path 58702.svg" />
              <h3>{t('Prestation.Qualified')}</h3>
              <p className="card-body">
                {t('Prestation.skilled')}
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card">
              <img src="/images/prestation/Path 58694.svg" alt="Path 58694.svg" />
              <h3>{t('Prestation.Development')}</h3>
              <p className="card-body">
                {t('Prestation.trained')}
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card">
              <img src="/images/prestation/development.svg" alt="development.svg" />
              <h3>{t('Prestation.Speed')}</h3>
              <p className="card-body">
                {t('Prestation.Agile')}
              </p>
            </div>
          </div>
        </div>
      </Partenaire>
    </div>
  </>)
}

const Externalisation = styled.div`
  padding:30px 15% 20px 15%;
  @media (max-width: 991px) {
    padding:30px 9% 20px 9% ;
  }
  @media (max-width: 500px) {
    padding:33px 9% 0px 9% ;
  }
  >.row{
    display: flex;
    list-style-type: none;
    padding: 0;
    font-weight: bold;
    @media (max-width: 983px){
      flex-direction: column;
        }
    >div{
      padding-bottom: 30px;
      @media (max-width: 983px){
            width: 100%;
        }
      >.card{
        padding:30px 30px 0 30px;
        border: 0;
        border-radius: 10px;
        box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
        height: 100%;
        >h3{
          @media (max-width:2560px) and (min-width: 984px) {
            height: 72px;
          }
          @media (max-width: 1494px) and (min-width: 984px) {
            height: 108px;
          }
          @media (max-width: 1143px) and (min-width: 984px) {
            height: 144px;
          }
          @media (max-width: 1069px) and (min-width: 984px) {
            height: 180px;
          }
          @media (max-width: 991px) and (min-width: 984px) {
            height: 108px;
          }
        }
        >img{
          margin-right: auto;
          height: 60px;
          margin-bottom: 20px;
        }
        >.card-body{
          bottom: 20px;
          padding:10px 0 30px 0;
          font-weight: normal;
          line-height: 28px;
        }
      }
      >.card-ar >img{
        margin-right: 0;
        margin-left: auto;
      }
    }
  
  }
`;

const Respect = styled.div`
  margin-top: 30px;
  padding-top:70px;
  background-color: #ecf6fb;
  @media (max-width: 500px) {
  margin-top: 0px;
  padding-top:24px;
  }
  >h2{
    padding:0px 15% 20px 15% ;
    text-align: center;
    font-weight:bold;
    @media (max-width:991px) {
      text-align: left;
      padding:0 10%;
    }
  }
  >.row{
    padding:20px 15% 50px 15%;
    display: flex;
    line-height: 28px;
    @media (max-width:991px) {
      margin:0 9% 0px 9%;
      padding:20px 0 50px 0;
    }
    @media (max-width: 983px){
            flex-direction: column;
        }
    @media (max-width: 1477px){
      justify-content: center;
    }
    >.left{
      padding-right: 30px;
      @media (max-width: 983px){
        width: 100%;
      }
    }
    >.left-ar{
      padding-right: 0;
      padding-left: 30px;
    }
    .right{
      padding-left: 50px;
      font-weight:bold;
      @media (max-width: 983px){
        padding-top: 30px;
        width: 100%;
        margin-left: 18px;
      }
      >ul{
        margin: 0;
        padding:0;
        list-style-type: none;
        list-style-image: url("/images/prestation/Path 4532.svg");
        >li{
          padding-left: 5px;
          padding-bottom: 10px;
          >img{
            padding-right: 10px;
            width: 20px;
          }
        }
      }
      >.ul-ar{
        list-style-image: url("/images/icons/arrow left.svg");
      }
    }
  }
`;

const VousAvez = styled.div`
  text-align: center;
  background-image: linear-gradient(to bottom right, #3881a8, #7dbad5);
  padding:55px 15% ;
  @media (max-width: 991px) {
    padding:60px 10% ;
  }
  @media (max-width: 500px) {
    padding:60px 10% 30px;
  }
  >h3{
    color:white !important;
    font-size: 24px !important;
    margin-bottom: 3px;
  }
  >.link button{
    width: 270px !important;
    color:#20ACE3;
    font-size: 16px;
    font-weight: 600;
    border-radius: 15px;
    background-color: white;
  }
`;

const Aventage = styled.div`
padding:30px 15% 80px 15%;
background-color: #ECF6FB;
@media (max-width: 991px) {
  padding:30px 7% 60px 7%;
  margin: 0;
}
>.row{
    padding: 0;
    margin: 0;
    @media (max-width: 1432px){
      justify-content: center;
    }
    >div{
      flex-basis:50%;
      @media (max-width: 627px) {
        flex-basis:100%;
      }
      >.like{
        background-image: -webkit-linear-gradient(top, #17406F 55px, transparent 52px, transparent 100%);
        >.Avantages >h3{
          color: white;
        }
      }
      >.risque{
        >.header{
          border-bottom: 1px solid #17406F;
        }
      }
      >.card{
        border: 0;
        border-radius: 15px;
        box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
        height: 100%;
        width: 100%;
        >.header{
          padding-left:15px;
          padding-bottom: 0;
          display: flex;
          flex-direction: row;
          >img{
            width: 21.5px;
            margin: 0 15px;
            height: auto;
            color: blue;
          }
          >h3{
            padding-top: 7px;
            font-size: 20px !important;
            
          }
        }
        >.card-body{
          padding:4px 30px 20px;
          >h4{
            font-family: PoppinsRegular;
            padding-top:10px ;
            margin-bottom: 0;
            color:#17406F;
            font-weight: 600;
          } 
          >p{
            margin-bottom: 0;
          }
        }
        }

    }
  
  }
`;

const Partenaire = styled.div`
  padding:70px 15% 60px 15% ;
  @media (max-width: 991px) {
    padding:70px 10% 60px 10%;
  }
  @media (max-width: 500px) {
    padding:30px 10% 60px 10%;
  }

  >h2{
      text-align: center;
      font-weight:bold;
      padding-bottom: 50px;
      @media (max-width:500px) {
        padding-bottom: 30px;
      }
  }
  >.row{
    display: flex;
    list-style-type: none;
    padding: 0;
    @media (max-width: 680px){
      justify-content: center;
    }
    >div{
      flex-basis: 25%;
      @media (max-width: 727px) {
        flex-basis: 100%;
      }
      @media (max-width: 1697px) and (min-width: 728px) {
        flex-basis: 50%;
      }
      >.card{
        padding:30px;
        border: 0;
        height: 100%;
        border-radius: 10px;
        min-width:260.41px;
        box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);          
      >img{
        width: 50px;
        height: 60px;
        padding-bottom: 20px;
        @media (max-width:500px) {
          padding-bottom: 0;
        }
      }
      >h3{
        font-size: 18px !important;
        @media (max-width:2484px) and (min-width: 1698px){
          height: 68px;
        }
        @media (max-width:1225px) and (min-width: 728px){
          height: 68px;
        }
        @media (max-width:500px) {
          margin-bottom: 0;
        }
      }
      >p{
          padding:0px 0 0 0;
        }
      }
    }
    
  }
`;

export default withTranslation()(Prestation)
