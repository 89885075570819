import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { Title, WhatIs } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import { Link } from 'react-router-dom';
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Cgv = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const [pages, setPages] = useState([]);
  const { t } = props
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        {/* {splitState[1] === "fr" &&
          <> */}
            <Title>
              <h1>Conditions générales de vente</h1>
              <p>
                <img className={langue === "ar" ? "img-accueil-ar" : ""} src="/images/accueil.png" alt="accueil" />
                <Link className="retour" to="/">{t('Home_link')}</Link> / {page[0] !== undefined && <span>Conditions générales de vente</span>}
              </p>
            </Title>
            <Body>
              <h2>A PROPOS DE L'AGENCE DIGITALE</h2>
              <p>
                <b>Oxton Digital</b>, est une marque déposé par SIB interactive group. <br />
                <b>SIB INTERACTIVE GROUP</b>
                , société par actions simplifiée est en activité depuis 1 an. Domiciliée à ERMONT (95120), elle est spécialisée dans le secteur d'activité de la programmation informatique. Societe.com recense 1 établissement et le dernier événement notable de cette entreprise date du 20-01-2020.<br />
                <b>Forme juridique : </b>
                SASU Société par actions simplifiée à associé unique.<br />
                <b>Noms commerciaux :</b> SIB INTERACTIVE GROUP.<br />
                <b>Téléphone :</b> <a data-dtype="d3ifr" data-local-attribute="d3ph" href="tel: +33 1 73 29 71 53" data-ved="2ahUKEwiYnOPNwuzxAhUD4BoKHYrFA3IQkAgoADAgegQILhAD" style={{ color: "#17406F" }}>+33 1 73 29 71 53</a><br />
                <b>Adresse postale:</b> 214 RUE DU GENERAL LECLERC 95120 ERMONT<br />
                <b>Numéro SIREN:</b> 880785514
              </p>
              <h2>Numéros d'identification</h2>
              <p>
                <b>Numéro SIREN:</b> 880785514<br />
                <b>Numéro SIRET (siège)</b>: 88078551400014<br />
                <b>Numéro TVA Intracommunautaire:</b> FR21880785514<br />
                <b>Numéro RCS</b>: Pontoise B 880 785 514
              </p>
              <h2>Informations commerciales</h2>
              <p>
                <b>Catégorie:</b> 	Informatique <br />
                <b>Activité (Code NAF ou APE)</b>: Programmation informatique (6201Z)<br />
              </p>
              <h2>Informations juridiques</h2>
              <p>
                <b>Date d'immatriculation RCS:</b>  Immatriculée au RCS le 20-01-2020<br />
                <b>Date d'enregistrement INSEE</b>: Enregistrée à l'INSEE le 17-01-2020
              </p>
                <h2>Taille de l'entreprise</h2>
              <p>
                <b>Capital social:</b> Capital social : 1 000,00 €<br />
              </p>
              <h2>CONDITIONS COMMERCIALES</h2>
              <p>
                Le fait d'accepter un devis ou de signer un bon de commande relatif à un produit commercial ou une prestation de services fournie par l'Agence Digitale vaut pour acceptation pleine et entière des conditions contractuelles que vous pourrez consulter ici :
              </p>
              <ul>
                <li><a style={{ color: "#17406F" }} href={`/${langue}/conditions-generales-de-vente-de-prestations-de-services`}>Conditions générales de vente de prestations de services</a></li>
                <li><a style={{ color: "#17406F" }} href={`/${langue}/conditions-generales-de-vente-dhebergement-internet`}>Conditions générales de vente d'hébergement internet</a></li>
                <li><a style={{ color: "#17406F" }} href={`/${langue}/conditions-generales-de-vente-maintenance-de-sites-internet`}>Conditions générales de vente maintenance de sites internet</a></li>
              </ul>
            </Body>
          {/* </>
        } */}
      </div>
    </>)
}

const Body = styled.div`
  padding:25px 15% 30px 15%;
  @media (max-width:991px) {
    padding:25px 10% 30px 10%;
  }
  h2{
    font-size: 20px !important;
  }
  ul{
    line-height: 28px;
  }
`

export default withTranslation()(Cgv)