import React, { useEffect, useState } from "react";
import Portfolio from "../../../Model/Portfolio";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const DetailPortfolio = (props) => {
  const { state } = props.location
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [portfolios, setPortfolios] = useState([]);
  const [pages, setPages] = useState([]);
  const [id, setId] = useState("");
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const scrollIntop = (id) => {
    window.scrollTo(0, 0);
    localStorage.setItem('idPortfolio', id);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    setId(localStorage.getItem('idPortfolio'))
    if (props.location.state != undefined) {
      setId(props.location.state.id)
    }

    localStorage.removeItem('idPortfolio');
    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var portfolioLange = []
          currentLanguage === "fr"
            ? (result.map(element => { element.excerpt.rendered === "<p>French</p>\n" && portfolioLange.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.excerpt.rendered === "<p>English</p>\n" && portfolioLange.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.excerpt.rendered === "<p>العربية</p>\n" && portfolioLange.push(element) }))
          setPortfolios(portfolioLange)
        }
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let item = [];
  portfolios.map(element => {
    splitState[1].length === 2 ?
      element._embedded['wp:featuredmedia'] ?
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[3]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[3]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered)) :
      element._embedded['wp:featuredmedia'] ?
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[2]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[2]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
  })

  let portfolio = [];
  portfolios.map(element => element._embedded['wp:featuredmedia']
    ? item[0] !== undefined && console.log() && item[0] !== undefined
      ? item[0].id !== portfolio.id && item[0].id <= portfolio.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
      : state !== undefined && element.id !== state.id && element.id <= state.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
    : item[0] !== undefined && console.log() &&
      item[0] !== undefined ? item[0].id !== portfolio.id && item[0].id <= portfolio.id && portfolio.length < 4 &&
    portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
      : state !== undefined && element.id !== state.id && element.id <= state.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
  )

  portfolios.map(element =>
    element._embedded['wp:featuredmedia']
      ? portfolio.length < 4
      && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
      portfolio.length < 4
      && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
  )

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/getTag.js";
    script.type = "text/javascript";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  function decodeHtmlCharCodes(str) {
    return str.replace(/(&#(\d+);)/g, function (charCode) {
      return String.fromCharCode(charCode);
    });
  }

  let titre = item[0] !== undefined ? item[0].title.replace("&#8217;", "'") : "...Loading"
  document.title = titre;

  function htmlToPara(val) {
    var p = portfolio[0] && new DOMParser().parseFromString(val, 'text/html') || null
    return p.getElementsByTagName('p')[1]
      ? p.getElementsByTagName('p')[1].innerHTML
      : t('DetailPortfolio.established')
  }

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        <Title>
          <h1>{state !== undefined ? decodeHtmlCharCodes(state.title) : item[0] !== undefined ? titre : "....Loading"}</h1>
          <p>
            <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
            <Link className="retour" to="/">{t('Home_link')}</Link> / <Link className="retour" to={langue === "fr" ? "/fr/nos-references" : langue === "en" ? "/en/portfolio" : "/ar/مَلَفّ"}>{t('Portfolio_subtitle')}</Link> / <span>{state !== undefined ? decodeHtmlCharCodes(state.title) : item[0] !== undefined ? titre : "....Loading"}</span>
          </p>
        </Title>
        <Details>
          <div className={langue === "ar" ? "left left-ar" : "left"}>
            <img src={state !== undefined ? state.image : item[0] !== undefined ? item[0].image : "....Loading"} alt={state !== undefined ? state.title : item[0] !== undefined ? item[0].title : "....Loading"} />
            <div className="details" dangerouslySetInnerHTML={{ __html: state !== undefined ? state.content : item[0] ? item[0].content : '....Loading' }} />
          </div>
          <div className={langue === "ar" ? "right right-ar" : "right"}>
            <Autres>
              <div className="list">
                <h2 className={langue === "ar" && "alignRightMobile"}>{t('DetailPortfolio.other')}</h2>
                <ul>
                  {portfolio.map((item, index) => (
                    <li key={index}>
                      <Link onClick={() => scrollIntop(item.id)} as={Link} to={langue === "fr" ? { pathname: `/fr/details-de-portfolio/${item.slug.replace(/[^a-zA-Z0-9.-]/g, "")}`, state: item } : { pathname: `/en/details-of-portfolio/${item.slug.replace(/[^a-zA-Z0-9.-]/g, "")}`, state: item }} className="text-black text-decoration-none link" key={item.id} >
                        <div className={langue === "ar" ? "card card-ar" : "card"}>
                          <img src={item.image} alt={item.title} />
                          <div className="card-body">
                            <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
                            <p dangerouslySetInnerHTML={{ __html: htmlToPara(item.content) }} />
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="technologies">
                <h2 className={langue === "ar" && "alignRightMobile"}>{t('DetailPortfolio.Technologies')}</h2>
                <bdi>
                  <ul>
                    <li><img src="/images/portfolio/html-5.svg" alt="HTML" />HTML</li>
                    <li><img src="/images/portfolio/css-3.svg" alt="css" />CSS</li>
                    <li><img src="/images/portfolio/js.svg" alt="js" />JS</li>
                    <li><img src="/images/portfolio/technologies/Laravel.png" alt="Laravel" />LARAVEL</li>
                    <li><img src="/images/portfolio/technologies/Prestashop.svg" alt="Prestashop" />PRESTASHOP</li>
                    <li><img src="/images/portfolio/technologies/Wordpress.png" alt="Wordpress" />WORDPRESS</li>
                    <li><img src="/images/portfolio/technologies/Nodejs.png" alt="Nodejs" />NODEJS</li>
                    <li><img src="/images/portfolio/technologies/React.png" alt="React" />REACT</li>
                  </ul>
                </bdi>
              </div>
            </Autres>
          </div>
        </Details>
      </div>
    </>
  )
}

const Details = styled.div`
  display: flex;
  @media (max-width: 1128px){
    flex-direction: column;
  }
  >.left{
    width: 65%;
    padding: 30px 0 35px 70px;
    >.details >.hashtag >p >.tag{
        cursor: pointer;
    }
    @media (max-width: 1128px){
      padding: 30px 65px 35px 65px;
    }
    /* @media (max-width: 520px){
      padding: 30px 0% 35px 0%;
    } */
    @media (max-width: 520px){
      padding: 30px 10% 35px;
    }
    @media (max-width: 1128px){
      width: 100%;
    }
    @media (max-width: 1159px) and (min-width: 1129px){
      width: 50%;
    }
    @media (max-width: 1299px) and (min-width: 1160px){
      width: 55%;
    }
    @media (max-width: 1450px) and (min-width: 1300px){
      width: 60%;
    }
    >img{
      border-radius: 25px;
      width: 100%;
      max-height: 970px;
      object-fit: cover;
      margin-bottom: 50px;
      @media (max-width: 425px) {
        height: 230px;
        object-fit: cover;
      }
    }
    >div .hashtag{
      display: flex;
      justify-content:space-between;
      flex-wrap: wrap;
      >a{
        color:#20ACE3;
        font-family: EurostileExtendedBold;
        text-decoration: none;
        @media (max-width: 582px) {
          padding-bottom: 20px;
        }
      >img{
        background-color: #ECF6FB;
        padding: 4px;
        border-radius: 7px;
      }
      }
    }
  }
  >.left-ar{
    padding: 30px 70px 35px 30px;
    @media (max-width: 520px){
      padding: 30px 10% 35px;
    }
  }
  >.right{
    padding: 30px 70px 35px 0px;
    @media (max-width: 1129px){
      padding: 30px 0 35px;
    }
    @media (max-width: 520px){
      padding: 30px 0 35px;
    }
    width: 35%;
    @media (max-width: 1128px){
      width: 100%;
      margin: 0;
      padding: 0;
    }
    @media (max-width: 1159px) and (min-width: 1129px){
    width: 50%;
    }
    @media (max-width: 1299px) and (min-width: 1160px){
      width:45%;
    }
    @media (max-width: 1450px) and (min-width: 1300px){
      width:40%;
      min-width: 400px;
    }
  }
  >.right-ar{
    @media (min-width: 1129px) {
      padding: 30px 0 35px 70px;
    }
    >div >.list >ul{
      padding-right: 0;
    }
  }
`;

const Autres = styled.div`
  margin-left: 30px;
  @media (max-width: 1128px){
    margin-left: 0;
  }
  >.list{
    background-color: #ECF6FB;
    border-radius: 15px;
    padding:10px 20px;
    @media (max-width:1128px) {
      margin-top: 15px;
      padding:20px 70px 10px 70px;    
    }
    @media (max-width:520px) {
      padding:20px 10% 10px 10%;    
    }
    >h2{
      font-size: 24px !important;
    }
    >ul{
      padding-left: 0;
      @media (max-width:1128px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: 979px){
          flex-direction: column;
        }
      }
      >li{
        margin: 9px 0;
        list-style-type: none;
        @media (max-width:1128px) and (min-width: 979px) {
          width: 49%;
        }
        >.link{
          >.card{
            box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
            background-color: white;
            border-radius: 15px;
            height: 100px;
            display: flex;
            flex-direction: row;
            @media (max-width:560px) {
              height: auto;
              flex-direction: column;
            }
            >img{
              display: block;
              object-fit: cover;
              min-width: 135px;
              border-radius:15px 0 0 15px;
              @media (max-width:560px) {
                height: auto;
                width: 100%;
                border-radius:15px 15px 0 0;
              }
            }
            >.card-body{
              padding-top: 12px;
              font-size: 16px;
              overflow: hidden;
              @media (max-width:560px) {
                  margin-bottom: 10px;
                }
              >h3{
                padding-top: 0;
                font-size: 18px !important;
                color: #17406F;
                margin-bottom: 0 !important;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
              >p{
                font-size: 14px;
                line-height: 1.5em !important;
                height: 3em;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;  
                -webkit-box-orient: vertical;
                @media (min-width:828px) {
                  /* white-space: nowrap; */
                }
                /* @media (max-width:560px) {
                  padding-bottom: 50px;
                } */
              }
            }
            &:hover > div h3{
              color: #20ACE3;
            }
          }
          >.card-ar{
            @media (min-width: 561px) {
              flex-direction: row-reverse;
            }
          }
        } 
      }
    }
  }
  >.technologies{
    padding-top: 30px;
    padding-left: 0px;
    font-weight: bold;
    @media (max-width:1128px) {
      margin-top: 15px;
      padding:20px 70px 70px 70px;    
    }
    @media (max-width:520px) {
      padding-left: 37px;
      padding-right: 37px;
      
    }
    >h2{
      font-size: 24px !important;
      padding-left:20px;
    }
    
    >bdi >ul {
      list-style: none;
      display: flex;
      flex-flow: wrap;
      padding-left: 22px;
      >li {
        display:flex;
        align-items: center;
        padding-right: 20px;
        margin-bottom: 10px;
        >img{
          padding-right: 10px;
          /* padding-left: 20px; */
          height: 35px;
        }
      }
    } 
  }
  `;

export default withTranslation()(DetailPortfolio)
