import React, { useState, useEffect } from "react";
import './AllPages.css';
import { URL_Back } from "../../components/Pages/Variables";
import Contacter from "../../Model/Contacter";
import styled from "styled-components"
import Pages from "../../Model/Pages";
import { withTranslation } from "react-i18next";
import cookies from "js-cookie";
// import PopupForm from "../Pages/Forms/PopupForm";

const NavBar = (props) => {
  const { t } = props

  const [contacter, setContacter] = useState([])
  const [reviews, setReviews] = useState([]);
  const [reviewHide, setReviewHide] = useState('');
  const [langue, setLangue] = useState("fr");

  const hideReview = () => {
    setReviewHide('hide')
  }

  const currentLanguage = cookies.get('i18next') || 'fr'
  useEffect(() => {
    setLangue(currentLanguage)
  },[currentLanguage])

  useEffect(() => {
    fetch(`${URL_Back}/wp-json/wp/v2/contacter?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setContacter(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/reviews`)
      .then(res => res.json())
      .then(
        (result) => {
          setReviews(result)
        },
      )
  }, [])

  let contacterEmail = []
  let contacterTel = []
  contacter.map(element =>
    element.title.rendered === "Email" &&
    contacterEmail.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element.image)))
  contacter.map(element =>
    element.title.rendered === "Telephone" &&
    contacterTel.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
  )

  let review = []
  reviews.map(element =>
    review.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered)))

  return (
    <div className="background" >
      <img className={ langue ==="ar"? "cercles cercles-ar" : "cercles" } src="/images/allPages/cercles.svg" alt="Cercles" />
      <img className={ langue ==="ar"? "cercle cercle-ar" : "cercle" } src="/images/navbar/Ellipse 27.png" alt="Ellipse 27" />
      <div className="contact">
        <span className={ langue ==="ar"? "text1 text1-ar" : "text1" }>
          <span className="vertical-line line-contact" />
          {t('Contact_us')}
        </span>
        <span className={ langue ==="ar"? "text2 text2-ar" : "text2" }>
          {contacterEmail.map((item, key) => (
            <a key={key} className="aLink" href={`mailto:${item.content.slice(4, -5)}`}>{item.content.slice(4, -5)}</a>
          ))}
          {contacterTel.map((item, key) => (
            <span key={key}>
              <img className="drapo" src={item.image} alt={item.title} />
              <a className="aLink" href={`tel:${item.content.slice(4, -5)}`}>{item.content.slice(4, -5)}</a>
            </span>
          ))}
        </span>
      </div>
      <div className={langue ==="ar" ? "follow follow-ar" : "follow"}>
        {t('Follow_us')}
        <span className="vertical-line"/>
        <a href="https://www.facebook.com/oxtondigital" target="_blank" rel="noreferrer"><img className="icon" src="/images/allPages/_x30_1._Facebook.svg" alt="facebook" /></a>
        <a href="https://www.linkedin.com/company/oxton-digital/home/" target="_blank" rel="noreferrer"><img className="icon" src="/images/allPages/_x31_0.Linkedin.svg" alt="linkedin" /></a>
        <a href="https://www.instagram.com/oxton_digital_agency/" target="_blank" rel="noreferrer"><img className="icon" src="/images/allPages/_x32_2._Instagram.svg" alt="instagram" /></a>
      </div>
      <Reviews className={langue === "ar" ?`${reviewHide} review-ar` : `${reviewHide}`}>
        <svg className={`${reviewHide}`} onClick={() => hideReview()} xmlns="http://www.w3.org/2000/svg" width="26.002" height="26.001" viewBox="0 0 26.002 26.001">
          <g id="_02_User" data-name="02 User" transform="translate(0.006 0.003)">
            <path id="Path_92024" data-name="Path 92024" d="M1.264,26a1.27,1.27,0,0,1-.9-2.167L23.828.369a1.27,1.27,0,1,1,1.8,1.8L2.162,25.626a1.266,1.266,0,0,1-.9.372Z" transform="translate(0 0)" fill="#043e70" />
            <path id="Path_92025" data-name="Path 92025" d="M24.728,26a1.266,1.266,0,0,1-.9-.372L.369,2.164a1.27,1.27,0,1,1,1.8-1.8L25.626,23.831a1.27,1.27,0,0,1-.9,2.167Z" transform="translate(-0.002)" fill="#043e70" />
          </g>
        </svg>
        {reviews[0] !== undefined &&
          <a className={`${reviewHide}`} href="https://www.google.com/maps/place/Oxton+Digital/@48.9825979,2.2714041,17z/data=!4m7!3m6!1s0x0:0x138c72dfcc846902!8m2!3d48.9825979!4d2.2714041!9m1!1b1" target="_blank">
            <div dangerouslySetInnerHTML={{ __html: review[0].content }} />
          </a>}
      </Reviews>
      {/* <PopupForm subprops={props}/>  */}
    </div>
  )
}

const Reviews = styled.div`
  position: fixed;
  right: 50px;
  bottom: 80px;
  z-index: 200;

  @media (max-width: 991px) {
    right: 5px;
    bottom: 5px;
  }
  >svg{
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #efefef;
    padding: 3px;
    cursor: pointer;
  }
  >a{
    text-decoration: none;
  }
  >.hide {
    display: none;
  }
  .wp-google-list{
    color: #17406F !important;
    background: #efefef;
    padding: 7px;
    width: 110px;
    border-radius: 30px;
    text-align: center;
    margin-left: auto;
    border: 2px solid #fff;
  }
  .wp-google-rating{
    display: block;
    font-size: 24px;
    text-align: center;
  }
  .wp-google-powered{
    font-size: 11px;
    text-decoration: none;
    >img{
      padding-top: 10px;
      padding-bottom: 10px;
      height: auto;
      width: 85px;
    }
  }
  .wp-google-left{
    display: none;
  }
  .wp-google-name{
    display: none;
  }
`;

export default withTranslation()(NavBar)