import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';
import Partenaire from "../../Model/Partenaire";
import Contacter from "../../Model/Contacter";
import FooterD from "../../Model/Footer";
import { URL_Back, URL_Dev } from "../Pages/Variables";
import { withTranslation } from "react-i18next";
import cookies from "js-cookie";

const Footer = (props) => {
  const title = window.location.href
  const splitState = title.split('/')
  const { t } = props
  const [items, setItems] = useState([]);
  const [services, setServices] = useState(false)
  const [contact, setContact] = useState(0)
  const [partenaires, setPartenaires] = useState(0)
  const [contacter, setContacter] = useState([])
  const [footer, setFooter] = useState([])
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
  }, [currentLanguage])

  useEffect(() => {

    fetch(`${URL_Back}/wp-json/wp/v2/footer?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setFooter(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/partenaires?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        }
      )
    fetch(`${URL_Back}/wp-json/wp/v2/contacter?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setContacter(result)
        },
      )
  }, [])

  let selectedNames = ['Elotouch', 'Microsoft', 'OVH' , 'Verifone' , 'CashDro' , 'Trivec'];
  let partenaire = items
  .filter(element => selectedNames.includes(element.title.rendered))
  .map(element => new Partenaire(
    element.id, 
    element.title.rendered, 
    element.excerpt.rendered, 
    element.date, 
    element._embedded['wp:featuredmedia'][0].source_url
  ));


  let contacterEmail = []
  let contacterTel = []
  let contacterGlobal = []
  contacter.map(element =>
    element.title.rendered === "Email" &&
    contacterEmail.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)))
  contacter.map(element =>
    element.title.rendered === "Telephone" &&
    contacterTel.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)))
  contacter.map(element =>
    element.title.rendered === "Global" &&
    contacterGlobal.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)))

  let footerDescription = []
  footer.map(element =>
    element.title.rendered === "Description" &&
    (element.excerpt.rendered === "<p>العربية</p>\n" && langue === "ar"
      ? footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
      : element.excerpt.rendered === "<p>Français</p>\n" && langue === "fr"
        ? footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
        : element.excerpt.rendered === "<p>English</p>\n" && langue === "en"
        && footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
    )
  )

  let footerService = []
  footer.map(element =>
    element.title.rendered === "Services" &&
    (element.excerpt.rendered === "<p>العربية</p>\n" && langue === "ar"
      ? footerService.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date))
      : element.excerpt.rendered === "<p>Français</p>\n" && langue === "fr"
        ? footerService.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date))
        : element.excerpt.rendered === "<p>English</p>\n" && langue === "en"
        && footerService.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date))
    ))


  return (<>
    <div className="allFooter">
      <div className="footer">
        <div className={langue === "ar" ? "description description-ar" : "description"}>
          {footerDescription[0] !== undefined && <img className="logo logoFooter" src={footerDescription[0].image} alt="oxton" />}
          {footerDescription[0] !== undefined && <p dangerouslySetInnerHTML={{ __html: footerDescription[0].content }} />}
        </div>
        <div className="services">
          <div className="buttonFooter" onClick={() => services ? setServices(false) : setServices(true)}>
            <p className="title">{t('Our_services')}</p>
            <span className="hideFooter title">
              {services ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}
            </span>
          </div>
          {footerService[0] !== undefined
            && <ul className={services
              ? langue === "ar"
                ? "service service-ar show"
                : "service show"
              : langue === "ar"
                ? "service service-ar hide"
                : "service hide"} dangerouslySetInnerHTML={{ __html: footerService[0].content }} />}
        </div>
        <hr className="hideFooter" />
        <div className="contacter">
          <div className="buttonFooter" onClick={() => contact ? setContact(false) : setContact(true)}>
            <p className="title" >{t('Contact_Us')}</p>
            <span className="hideFooter title">
              {contact ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}</span>
          </div>
          <ul className={contact
            ? langue === "ar" ? "UlContacter UlContacter-ar show" : "UlContacter show"
            : langue === "ar" ? "UlContacter UlContacter-ar hide" : "UlContacter hide"}>
            {contacterGlobal.map((item, key) => (
              <li key={key}>
                <img className="contacterImg global" src={item.image} alt={item.title} />
                <a key={item.id} className="aLink LinkGlobal" href={`${item.content.slice(4, -5)}`} target="_blank">{t('Support')}<br /></a>
              </li>
            ))}
            {contacterEmail.map((item, key) => (
              <li key={key}>
                <img className="contacterImg" src={item.image} alt={item.title} />
                <a key={item.id} className="aLink" href={`mailto:${item.content.slice(4, -5)}`}>{item.content.slice(4, -5)}<br /></a>
              </li>
            ))}
            {contacterTel.map((item, key) => (
              (key === 0) ?
                <li key={key}>
                  <img className="contacterImg" src="/images/footer/telephone (2).svg" alt="telephone" />
                  <img className="contacterImg" src={item.image} alt={item.title} />
                  <a className="aLink" href={`tel:${item.content.slice(4, -5)}`}><bdi>{item.content.slice(4, -5)}</bdi></a>
                </li> :
                <li key={key}>
                  <img className="contacterImg seconde" src={item.image} alt={item.title} />
                  <a className="aLink" href={`tel:${item.content.slice(4, -5)}`}><bdi>{item.content.slice(4, -5)}</bdi></a>
                </li>
            ))}
          </ul>
        </div>
        <hr className="hideFooter" />
        <div className="partenaires">
          <div className="buttonFooter" onClick={() => partenaires ? setPartenaires(false) : setPartenaires(true)}>
            <div className="title">{t('Our_Partners')}</div>
            <span className="hideFooter title">
              {partenaires ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}</span>
          </div>
          <div className={partenaires ? "group show groupShow" : "group hide grouphide"}>
            {partenaire.map(item => (
              <a href={item.excerpt.slice(3, -5)} target="_blank" key={item.id} >
                <img className={langue === "ar" ? "partenaire partenaire-ar" : "partenaire"} src={item.image} alt={item.title} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="copyright d-flex">
        <bdi><span> Copyright &copy; {new Date().getFullYear()} - Oxton Digital.</span>
          <Link to={`/${langue}/cgv`}>CGV |&nbsp;</Link>
          <Link to={langue === "fr" ? "/fr/mentions-legales" : langue === "en" ? "/en/terms-of-use" : "/ar/تعليمات-الاستخدام"}>{t('Legal')}</Link></bdi>
      </div>
    </div>
  </>)
}
export default withTranslation()(Footer)