export default class Client {
    constructor(id, title, date, image) {
        this.id = id;
        this.title = title;
        this.date = date;
        this.image = image;
    }
    setId(id) {
        this.id.set(id);
    }
    getId(id) {
        return this.age.get(id);
    }

    setTitle(title) {
        this.title.set(title);
    }
    getTitle(title) {
        return this.title.get(title);
    }

    setDate(date) {
        this.date.set(date);
    }
    getDate(date) {
        return this.date.get(date);
    }

    setImage(image) {
        this.image.set(image);
    }
    getImage(image) {
        return this.image.get(image);
    }


}