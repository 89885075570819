import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { Title } from "../../../css/commun";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../Variables";
import Progress from "../Apropos/Progress/progress";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";


const Services = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])


  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Title>
        <h1>{titre}</h1>
        <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Title>
      <Progress />
      <Solutions>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('The proposed solutions')} </h2>
        <div className="solution" onClick={() => first ? setFirst(false) : setFirst(true)}>
          <h3>{t('Services.With a content management')} </h3>
          <span className={first ? "color" : ""}>{first ? "-" : "+"} </span>
        </div>
        <p className={first ? "show" : "dont"}>
          {t('Services.Every business owner')}<br />
          {t('Services.A CMS is a platform')}<br />
          {t('Services.And because CMS solutions')}
        </p>
        <br className={first ? "dont" : "show"} />
        <div className="solution" onClick={() => second ? setSecond(false) : setSecond(true)}>
          <h3>{t('Services.Tailor-made solutions')} </h3>
          <span className={second ? "color" : ""}>{second ? "-" : "+"} </span>
        </div>
        <p className={second ? "show" : "dont"}>{t('Services.At Oxton Digital')}</p>
      </Solutions>
      <Pourquoi>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Services.Why create a custom website')} </h2>
        <div className="row">
          <div className="col-md-6 leftPourquoi">
            <div className="card">
              <span>01</span>
              <h3>{t('Services.For a fully personalized user experience')} </h3>
              <div className="card-body">
                <p>{t('Services.When you create a custom website')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 rightPourquoi">
            <div className="card">
              <span>02</span>
              <h3>{t('Services.For an optimal loading time')} </h3>
              <div className="card-body">
                <p>{t('Services.Custom websites')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 leftPourquoi">
            <div className="card">
              <span>03</span>
              <h3>{t('Services.For total control')}</h3>
              <div className="card-body">
                <p>{t('Services.When you create a custom website, you have full control')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 rightPourquoi">
            <div className="card">
              <span>04</span>
              <h3>{t('Services.For a website that evolves')} </h3>
              <div className="card-body">
                <p>{t('Services.A custom website is also more scalable')}</p>
              </div>
            </div>
          </div>
        </div>
      </Pourquoi>
      <Expérience>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('User experience and web design')} </h2>
        <img src="/images/services/Tracé 58694.svg" alt="Tracé 58694" />
        <div className="row web">
          <div className="col steps">
            <img src="/images/services/Groupe 19798.svg" alt="Groupe 19798" />
            <label>{t('Services.In the beginning was the web design')} </label>
            <p className="first">{t('Services.In the early days of the internet')}</p>
          </div>
          <div className="col steps">
            <img src="/images/services/ui.svg" alt="ui" />
            <label>{t('Services.And then there was the UX and UI')} </label>
            <p className="first">{t('Services.While UX refers')}</p>
          </div>
          <div className="col steps">
            <img src="/images/services/conception-ux.svg" alt="conception-ux" />
            <label>{t('Services.Then comes the responsive design')}</label>
            <p className="first">{t('Services.A responsive website')}</p>
          </div>
          <div className="col steps">
            <img src="/images/services/ux.svg" alt="ux" />
            <label>{t('Services.As well as good copywriting')} </label>
            <p className="first">{t('Services.Copywriting ')}</p>
          </div>
          <div className="col steps">
            <img src="/images/services/Groupe 19807.svg" alt="Groupe 19807" />
            <label>{t('Services.the natural referencing')} </label>
            <p className="first">{t('Services.SEO is the process')}</p>
          </div>
        </div>
        <div className={langue === "ar" ? "row mobile mobile-ar" : "row mobile"}>
          <div className="col-md-5 steps">
            <img src="/images/services/Groupe 19798.svg" alt="Groupe 19798" /><br />
            <label>{t('Services.In the beginning was the web design')} </label>
            <p>{t('Services.In the early days of the internet')}</p>
          </div>
          <div className="col-md-5 steps">
            <img src="/images/services/ui.svg" alt="ui" /><br />
            <label>{t('Services.And then there was the UX and UI')} </label>
            <p>{t('Services.While UX refers')}</p>
          </div>
          <div className="col-md-5 steps">
            <img src="/images/services/conception-ux.svg" alt="conception-ux" /><br />
            <label>{t('Services.Then comes the responsive design')} </label>
            <p>{t('Services.A responsive website')}</p>
          </div>
          <div className="col-md-5 steps">
            <img src="/images/services/ux.svg" alt="ux" /><br />
            <label>{t('Services.As well as good copywriting')}</label>
            <p>{t('Services.Copywriting ')}</p>
          </div>
          <div className="col-md-5 steps">
            <img src="/images/services/Groupe 19807.svg" alt="Groupe 19807" /><br />
            <label>{t('Services.the natural referencing')} </label>
            <p>{t('Services.SEO is the process')}</p>
          </div>
        </div>
      </Expérience>
      <Development>
        <div className={langue === "ar" ? "row row-ar" : "row"}>
          <div className="col-md-10 left">
            <h2 className={langue === "ar" && "alignRightMobile"}>{t('Services.Mobile application development')}</h2>
            <p>{t('Services.With over 15 years of experience')}</p>
            <p>{t('Services.We understand that every business is unique')}</p>
          </div>
          <div className="col-md-2 right">
            <img className="phone" src="/images/services/app-mobile.png" alt="Group 38873" />
          </div>
        </div>
      </Development>
      <WhatIs>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Services.SEO and natural referencing')}
        </h2>
        <p className={langue === "ar" ? "text alignRightMobile" : "text"}>
          {t('Services.Your website is attractive')} <br />
          {t('Services.This may be due to a visibility problem')}
        </p>
      </WhatIs>
      <Externalisation>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <img src="/images/prestation/Path 58702.svg" alt="Path 58702.svg" />
              <h3>{t('Services.SEO and natural referencing for more visibility')}</h3>
              <div className="card-body">
                <p>
                  {t('Services.Having a website design')}<br />
                  {t('Services.Our SEO specialists')}<br />
                  {t('Services.When your site is positioned')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <img src="/images/prestation/code.svg" alt="code.svg" />
              <h3>{t('Services.A lasting impact')} </h3>
              <div className="card-body">
                <p>
                  {t('Services.The referencing of your website')} <br />
                  {t('Services.Indeed we follow')} <br />
                  {t('Services.The SEO professionals trained')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Externalisation>
    </div>
  </>
  )
}

const Solutions = styled.div`
  margin:60px 15% 70px 15%;
  @media (max-width: 991px) {
    margin:30px 10% 60px 10%;
  }
  @media (min-width: 991px) {
    >h2{
      text-align: center;
    }
  }
  >h2{
    padding-bottom: 30px;
  }
  >p{
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid #EEF7FB;
  }
  >.show{
    display:block;
  }
  >.dont{
    display:none;
  }

  >.solution{
    background-color: #EEF7FB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 15px;
    >h3{
      padding-right: 40px;
    }
    >span{
      font-size: 30px;
      font-weight:800;
    }
    >.color{
      color: #20ACE3;
    }
  }
  
`;

const Pourquoi = styled.div`
  padding:60px 15% 40px 15%;
  @media (max-width: 991px) {
    padding:60px 10% 10px 10% ;
  }
  margin-bottom: 30px;
  background-image: linear-gradient(to bottom right, #367ba7, #94cce5);
  color:white;
  >h2{
    text-align:center;
    padding-bottom: 10px;
    @media (max-width:991px) {
      text-align: left ;
    }
  }
  >.row{
    display: flex;
    list-style-type: none;
    padding: 0;
    font-weight: bold;
    @media (max-width: 983px){
      flex-direction: column;
    }
    @media (max-width: 1454px){
      justify-content: center;
    }
    @media (min-width: 1099px){
      >.leftPourquoi{ 
        padding-right: 35px;
      }
      >.rightPourquoi{
        padding-left: 35px;
      }
    }
    >div{
      @media (max-width: 1098px){
        width: 100%;
      }
      >.card{
        padding-top:30px;
        border: 0;
        border-radius: 10px;
        background-color: transparent;
        @media (max-width: 991px) {
          padding-top:20px;
        }
        >span{
          font-size:32px;
          font-family: EurostileExtendedBold;
        }
        >h3{
          font-size: 16px !important;
          color: white;
          line-height: 28px !important;
          @media (max-width:1809px) and (min-width:1099px){
            min-height: 56px;
          }
          @media (max-width:991px) {
            margin-bottom: 0;
          }
        }
        >.card-body{
            padding:10px 0 10px 0;
            font-weight: normal;
            line-height: 28px;
          }
        }
      }
    }
`;

export const Expérience = styled.div`
  margin:0px 15% 80px 15%;
  padding-top: 15px;

  @media (min-width: 991px) {
    text-align: center;
  }
  @media (max-width: 1700px) {
    margin:60px 15% 40px 15%;
  }
  @media (max-width: 1600px) {
    margin:60px 15% 10px 15%;
  }
  @media (max-width: 1550px) {
    margin:60px 15% 90px 15%;
  }
  @media (max-width: 1440px) {
    margin:60px 15% 60px 15%;
  }
  @media (max-width: 991px) {
    margin:0px 13% 50px 10%;
    padding-top: 0;
  }
  @media (max-width: 460px) {
    margin:0px 16% 30px 10%;
  }
  @media (min-width: 1860px) {
    margin-left:auto;
    margin-right: auto;
  }
  max-width: 1300px;

  >h2{
    padding-bottom: 50px;
    @media (max-width: 991px) {
      padding-bottom: 0px; 
      margin-bottom :0 ;
    }
  }
  >img{
    max-height: 487px;
    @media (max-width: 1300px) {
     display: none;
   }
   width:100%;
   position: absolute;
   left: 0;
   padding: 0 14%;
   z-index: -1;
  }

  >.mobile{
    justify-content: space-between;
    @media (min-width: 1301px) {
      display: none;
    }
    >.steps{
      border: 1px solid #5AA9BA;
      border-radius:66px;
      padding: 20px;
      margin: 20px;
      text-align: center;
      >label{
        color: #20ACE3;
        font-weight: 600;
        padding: 10px 0;
        line-height: 28px;
        &:hover{
        background-color: #F9FDFF; 
        }
      }
      >img{
        width: 70px;
        margin-bottom: 20px;
     }
    }
  }
  >.mobile-ar >.steps{
    margin-right: 0;
  }
  >.web{
    height: 488px;
    @media (max-width: 1300px) {
      display: none;
    }
    @media (max-width: 1550px) {
      height: 400px;
    }

    text-align: center;
    >.steps{
      width: 20%;
      padding-top: 32px;
      height: 100%;
      &:hover, :focus{
      >.first{
        display: block;
      }
      >.second{
        display: block;
      }
      >img{
        display: none;
      }
      }
      >.second{
        display: none;
      }
      >.first{
        display: none;
        margin-bottom: 0;
        font-size: 14px;
      }
      >label{
        color: #20ACE3;
        &:hover{
        background-color: #F9FDFF; 
        }
      }
      &:nth-child(1) , :nth-child(5){
        padding-top: 125px;
      }
      &:nth-child(2) , :nth-child(4){
        padding-top: 330px;
        >p{
          position: relative;
          bottom: 100px;
        }
        &:hover{
          >label{
            position: relative;
            bottom:110px;
          }
        }
        @media (max-width:2560px) {
          padding-top: 330px;
        }
        @media (max-width:1810px) {
          padding-top: 290px;
        }
        @media (max-width:1715px) {
          padding-top: 250px;
        }
        @media (max-width:1658px) {
          padding-top: 230px;
        }
        @media (max-width:1600px) {
          padding-top: 200px;
        }
        @media (max-width:1560px) {
          padding-top: 180px;
        }
        @media (max-width:1465px) {
          padding-top: 150px;
        }
        @media (max-width:1385px) {
          padding-top: 120px;
        }
      }
      >img{
        width: 70px;
        margin-bottom: 20px;
        z-index: -1;
      }
  }
 }
`;

export const Development = styled.div`
  background-color: #EEF7FB;
  >.row{
    .left{
      padding:80px 100px 72px 15%;
      @media (max-width: 991px) {
        padding:30px 10%;
      }
      @media (max-width:1020px) {
        width: 100%;
      }
      >h2{
        margin-bottom: 10px;
      }
      >p{
        margin-bottom: 8px;
      }
    }
    .right{
      background-repeat: no-repeat;
      border-left:70px solid transparent;
      border-bottom: 306px solid #367ba7;
      @media (max-width:2388px) {
        border-bottom: 334px solid #367ba7 !important;
      }
      @media (max-width:2023px) {
        border-bottom: 362px solid #367ba7 !important;
      }
      @media (max-width:1443px) {
        border-bottom: 395px solid #367ba7 !important;
      }
      @media (max-width:1277px) {
        border-bottom: 433px solid #367ba7 !important;
      }
      @media (max-width:1246px) {
        border-bottom: 460px solid #367ba7 !important;
      }
      @media (max-width:1110px) {
        border-bottom: 490px solid #367ba7 !important;
      }
      @media (max-width:1020px) {
          display: none;
        }
      @media (max-width: 1020px) {
        border-bottom: 440px solid transparent !important;
      }
      >.phone{
        width: 240px;
        position: absolute;
        right: 200px;
        margin-top: -5px;
        @media (max-width:2388px) {
          margin-top: 10px;
        }
        @media (max-width:2023px) {
          margin-top: 20px;
        }
        @media (max-width:1443px) {
          margin-top: 40px;
        }
        @media (max-width:1277px) {
          padding-top: 20px;
        }
        @media (max-width:1246px) {
          padding-top: 40px;
        }
        @media (max-width:2560px){
          right: 240px;
        }
        @media (max-width:2500px){
          right: 230px;
        }
        @media (max-width:2460px){
          right: 220px;
        }
        @media (max-width:2360px) {
          right: 200px;
        }
        @media (max-width:2260px) {
          right: 190px;
        }
        @media (max-width:2160px) {
          right: 180px;
        }
        @media (max-width:2090px) {
          right: 160px;
        }
        @media (max-width:1990px) {
          right: 140px;
        }
        @media (max-width:1870px) {
          right: 120px;
        }
        @media (max-width:1750px) {
          right: 100px;
        }
        @media (max-width:1630px) {
          right: 80px;
        }
        @media (max-width:1500px) {
          right: 70px;
        }
        @media (max-width:1440px) {
          right: 60px;
        } 
        @media (max-width:1370px) {
          right: 40px;
        }
        @media (max-width:1250px) {
          right: 25px;
        }
        @media (max-width:1150px) {
          right: 15px;
        }
        @media (max-width:1100px) {
          right: 5px;
        }
        @media (max-width:1050px) {
          right: 0px;
        }
      }
    }
  }
`;

const WhatIs = styled.div`
  padding:40px 15% 10px 15% ;
  text-align: center;
  @media (max-width: 991px){
    padding: 40px 10% 10px 10% !important;
  }
  >h2{
    text-align: center;
    padding-bottom: 10px;
    @media (min-width:991px) {
      padding: 30px 0 10px 0;
    }
    @media (max-width:991px) {
      text-align: left;
    }
  }
  .text{
    line-height: 28px;
    @media (max-width:991px) {
      text-align: left ;
    }
  }
`;

const Externalisation = styled.div`
  padding:30px 15% 60px 15%;
  @media (max-width: 991px) {
        padding:30px 9% 60px 9% ;
    }
  
  >.row{
    display: flex;
    list-style-type: none;
    padding: 0;
    font-weight: bold;
    @media (max-width: 983px){
            flex-direction: column;
        }
    @media (max-width: 1454px){
      justify-content: center;
    }
    
    >div{
      padding-bottom: 30px;
      @media (max-width: 983px){
        width: 100%;
      }
      >.card{
        height: 100%;
        padding:30px 30px 40px 30px;
        border: 0;
        border-radius: 10px;
        box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
        >img{
          width: fit-content;
          height: 60px;
          margin-bottom: 20px;
        }
        >h3{
          font-size: 18px !important;
          line-height: 28px !important;
        }
        >.card-body{
          padding:15px 0 0px 0;
          font-weight: normal;
          line-height: 28px;
          >label {
            padding-top: 5px;
            color:#20ACE3;
            font-family: Eurostile;
            font-weight: 600;
            cursor: pointer;
            @media (max-width: 500px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;

export default withTranslation()(Services)