import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { WhatIs, Title } from "../../../css/commun";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import Progress from "./Progress/progress";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Entreprise = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Title>
        <h1>{titre}</h1>
        <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <Link className="retour" to={`/${langue}`}>{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Title>
      <WhatIs>
        <h2 className={langue === "ar" && "alignRightMobile"}>
          {t('Oxton Digital')}, {t('Entreprise.Your Full Services digital agency')}
        </h2>
        <p className={langue === "ar"? "text alignRightMobile": "text"}>
          {t('Entreprise.If you are looking for a digital agency')}<br />
          {t('Entreprise.We take the time to listen to your specific goals')} <br />
          {t('Entreprise.once your site is up and running')}
        </p>
      </WhatIs>
      <Details className={langue === "ar" && "details-ar"}>
        <div className="left">
          <img className="image1" src="/images/entreprise/Mask Group 582.png" alt="Mask Group 582" />
          <img className="image2" src="/images/entreprise/Mask Group 777.png" alt="Mask Group 777" />
        </div>
        <div className="right">
          <Description>
            <h2 className={langue === "ar" && "alignRight"}>{t('Entreprise.Why Oxton Digital')}</h2>
            <p>
              {t('Entreprise.The effectiveness of a trading company')}<br /><br />
              {t('Entreprise.One of our main concerns')}<br /><br />
              {t('Entreprise.The success of our company')}
            </p>
            <Link className="link" to={langue === "fr" ? "/fr/contact" : langue === "en" ?  "/en/contact-us" : "/ar/اتصل-بنا"}><button className={langue === "ar" ? "btn btn-primary button button-ar joindre" : "btn btn-primary button joindre"}><span className="pseudo-text">{t('Contact us')} </span></button></Link>
          </Description>
        </div>
      </Details>
      <Pourquoi>
        <h2 className={langue === "ar" && "h2-ar"}>{t('Entreprise.What we can do for you')}</h2>
        <div>
          <div className={langue === "ar" ? "left left-ar" : "left"}>
            <p>{t('Entreprise.We offer affordable')}</p>
          </div>
          <div className={langue === "ar"? "right right-ar" : "right"}>
            <ul className={langue === "ar" && "ul-ar"}>
              <li>{t('Entreprise.A technical team')}</li>
              <li>{t('Entreprise.A team of marketing')}</li>
              <li>{t('Entreprise.We have access to data and analysis tools')}</li>
              <li>{t('Entreprise.Our support team')}</li>
              <li>{t('Entreprise.We will accompany you')}</li>
            </ul>
          </div>
        </div>
      </Pourquoi>
      <Progress />
      <Valeurs>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Entreprise.values')}</h2>
        <div className="row gy-4">
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                <img src="/images/entreprise/Group 19873.svg" alt="Group 20132.svg" />
                <h3>{t('Agility')}</h3>
              </div>
              <p className="card-body">
                {t('Entreprise.We work in agile mode')}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                <img src="/images/entreprise/Group 19874.svg" alt="Group 20132.svg" />
                <h3>{t('Team spirit')}</h3>
              </div>
              <p className="card-body">
                {t('Entreprise.Our employees work in a friendly')}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                <img src="/images/entreprise/Path 64607.svg" alt="Group 20132.svg" />
                <h3>{t('Customer centric')}</h3>
              </div>
              <p className="card-body">
                {t('Entreprise.The customer is at the center')}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                <img src="/images/entreprise/Group 19872.svg" alt="Group 20132.svg" />
                <h3>{t('Open minds')}</h3>
              </div>
              <p className="card-body">
                {t('Entreprise.We are oriented towards')}
              </p>
            </div>
          </div>
        </div>
      </Valeurs>
    </div>
  </>)
}

const Details = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.details-ar{
    flex-direction: row-reverse;
  }
  padding:25px 15% 30px 15%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width:991px) {
    padding:25px 10% 30px 10%;
  }
  @media (max-width:500px) {
    padding:25px 10% 0px 10%;
  }
  .left{
    width: 50%;
    overflow: hidden;
    border-radius: 16px;
    .image2{
      display: none;
      width: 100%;
    }
    >img{
      height: 100%;
    }
    @media (max-width:991px) and (min-width: 814px) {
      width: 48%;
      margin-right: 25px;
    }
    @media (max-width:1060px){
      .image1{
        display: none;
      }
      .image2{
        display: block;
      }
    }
  }
  .right{
    width: 50%;
    @media (max-width:991px)and (min-width: 814px) {
      width: 48%;
    }
  }
  @media (max-width:1060px){
    .left{
      width: 100% !important;
    }
    .right{
      width: 100% !important;
      padding-top: 30px;
      margin-left: -30px !important;
    }
  }
`;

const Description = styled.div`
  padding-left: 30px;
  line-height: 24px;
  >h2{
    padding-bottom: 14px;
    text-align: left;
  }
  >.link >.joindre{
    width: auto !important;
    margin-top: 15px;
    margin-left: 0;
    padding: 10px 30px !important;
    }
`;

const Pourquoi = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  background-color: #ecf6fb;
  padding: 50px 0 60px;
  @media (max-width:991px) {
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 40px 10% 30px;
  }
  @media (max-width:500px) {
    padding: 20px 10% 0px;
  }
  >h2{
    text-align: center;
    line-height: 38px !important;
    padding-bottom: 40px;
    @media (max-width: 991px) {
      padding-bottom: 0px;
    }
  }
  
  >div{
    display: flex;
    font-size: 16px;
    line-height: 28px;
    padding: 0 15%;
    @media (max-width:991px) {
      padding: 0;
    }
    >.left{
      width: 50%;        
      padding-right: 22px;
      @media (max-width:991px) {
        padding: 0;
      }
    }
    >.left-ar{
      padding-left: 22px;
      padding-right: 0;
    }
    >.right{
      width:50%;   
      min-width: 23drem;
      font-weight:bold;
      
      >ul{
        margin: 0;
        padding:0;
        list-style-type: none;
        list-style-image: url("/images/prestation/Path 4532.svg");
        padding-left: 42px;
        @media (max-width:991px) {
          padding-left: 10px;
        }
        >li{
          padding-left: 5px;
          padding-bottom: 10px;
          @media (max-width:500px) {
            padding-bottom: 30px;

          }
          >img{
              padding-right: 10px;
              width: 20px;
          }
          &::marker{
            border:1px solid red
          }
        }
      }
      >ul.ul-ar{
        list-style-image: url("/images/icons/arrow left.svg");
      }
    }
  }
  @media (max-width: 991px){
    >h2{
      text-align: left !important;
    }
    >.h2-ar{
      text-align: right !important;
    }

    >div{
      flex-direction: column;
    }
    justify-content: center;
    .right{
      padding-top: 10px;
      width: 100% !important;
      margin-left: 10px;
    }
    .right-ar{
      margin-right: 20px;
    }
    .left{
      width: 100% !important;
      margin-right: 20px !important;
      padding-right: 0 !important;
    }
    .left-ar{
      margin-right: 0 !important;
      margin-left: 20px !important;
    }
  }
`;

const Valeurs = styled.div`
  margin:0px 10% 60px 10%;

  >h2{
    text-align: center;
    padding-bottom: 60px;
    @media (max-width:991px) {
      text-align: left;
      padding-bottom: 15px;

    }
  }
>.row >div{
  @media (max-width:1256px) and (min-width:600px) {
    width: 50%;
  }
  @media (max-width:600px) {
    width: 100%;
  }
  
  >.card{
    padding:16px 16px 0 16px;
    min-width: 192px;
    box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
    border-radius: 10px;
    height: 100%;
    >.card-header{
      background-color: transparent;
      border-bottom: 0;
      display: flex;
      flex-direction: column;
      @media (max-width: 1436px) and (min-width: 1257px){
        height: 160px;
      }
      @media (max-width: 702px) and (min-width: 601px){
        height: 160px;
      }
      >h3{
        font-size: 20px !important;
        margin-bottom: 0;
      }
      >img{
      width: 52px;
      height: 52px;
      margin-bottom: 15px;
      } 
    }
  
    >.card-body{
      padding-top:0 !important;
      line-height: 28px;
    }
  
  @media (max-width:794px) {
    justify-content: center;
  }
  @media (max-width: 700px){
      display: flex;
      justify-content: center;
      }
  >.tek {
    >div{
      >div div{
        border: 0;          
        }
      }
  }
}
  }
`;



export default withTranslation()(Entreprise);