import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Portfolio from "../../../Model/Portfolio";
import styled from "styled-components";
import { Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import { ListPortfolio } from "./ListPortfolio";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import { TailSpin } from 'react-loader-spinner';

const Portfolios = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [items, setItems] = useState([]);
  const [portfolioCategori, setPortfolioCategori] = useState(0);
  const [number, setNumber] = useState(12)
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const [loading, setLoading] = useState('true')
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'

    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var portfolioLange = []
          currentLanguage === "fr"
            ? (result.map(element => { element.excerpt.rendered === "<p>French</p>\n" && portfolioLange.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.excerpt.rendered === "<p>English</p>\n" && portfolioLange.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.excerpt.rendered === "<p>العربية</p>\n" && portfolioLange.push(element) }))
          setItems(portfolioLange)
          setLoading(false)
        },
      )
  }, [currentLanguage])

  const scrollToEnd = () => {
    setNumber(number + 3)
  }

  window.onscroll = function () {
    if (window.innerHeight + document.documentElement.scrollTop > (document.documentElement.offsetHeight - 300)) {
      scrollToEnd()
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let portfolio = [];
  (portfolioCategori === 0) ?
    items.map(
      element => element._embedded['wp:featuredmedia']
        ? portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
        : portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
    ) : items.map(element => element._embedded['wp:featuredmedia']
      ? element.categoriesportfolio.map(val => (val === portfolioCategori && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))))
      : element.categoriesportfolio.map(val => (val === portfolioCategori && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered)))
      )
    )

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        <Title>
          <h1>{titre}</h1>
          <p> <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
            <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span></p>
        </Title>

        <WhatIs>
          <h2 className={langue === "ar" && "alignRightMobile"}>
            {t('Portfolio.applications')}
          </h2>
          <p className={langue === "ar" ? "text text-ar" : "text"} >
            {t('Portfolio.work')}
          </p>
        </WhatIs>
        <Table>
          <div className="listBotton">
            <button type="button" className={portfolioCategori === 0 ? "btn btn-light active" : "btn btn-light"} onClick={() => (setPortfolioCategori(0), setNumber(6))}>{t('Portfolio.All')}</button>
            <div className="d-none container">
              <h3 className='text-white'>{t('Portfolio.references')}</h3>
              <p className='text-white'>{t('Portfolio.custom_solutions')}</p>
            </div>
            <button type="button" className={portfolioCategori === 5 ? "btn btn-light active" : "btn btn-light"} onClick={() => (setPortfolioCategori(5), setNumber(6))}>{t('Portfolio.Web_development')}</button>
            <div className="d-none container">
              <h3 className='text-white'>{t('Portfolio.examples')}</h3>
              <p className='text-white'>{t('Portfolio.beautiful')}</p>
            </div>
            <button type="button" className={portfolioCategori === 6 ? "btn btn-light active" : "btn btn-light"} onClick={() => (setPortfolioCategori(6), setNumber(6))}>{t('Mobile')}</button>
            <div className="d-none container">
              <h3 className='text-white'>{t('Portfolio.mobile')}</h3>
              <p className='text-white'>{t('Portfolio.record')}</p>
            </div>
            <button type="button" className={portfolioCategori === 7 ? "btn btn-light active" : "btn btn-light"} onClick={() => (setPortfolioCategori(7), setNumber(6))}>{t('Portfolio.UX_UI_Design')}</button>
            <div className="d-none container">
              <h3 className='text-white'>{t('Portfolio.examples_design')}</h3>
              <p className='text-white'>{t('Portfolio.graphic')}</p>
            </div>
            <button type="button" className={portfolioCategori === 9 ? "btn btn-light active" : "btn btn-light"} onClick={() => (setPortfolioCategori(9), setNumber(6))}>{t('Kiosk')}</button>
            <div className="d-none container">
              <h3 className='text-white'>{t('Portfolio.examples_kiosks')}</h3>
              <p className='text-white'>{t('Portfolio.dedicated')}</p>
            </div>
          </div>
          {loading
            ? <div className="loading">
              <TailSpin color="#17406F" />
            </div>
            : <>
              <ListPortfolio portfolio={portfolio} number={number} langue={langue} />
              {(portfolioCategori === 0 && number < items.length) ? <img className="arrow" src="/images/portfolio/Group 20195.svg" alt="arrow" onClick={() => setNumber(number + 9)} />
                : number < portfolio.length && <img className="arrow" src="/images/portfolio/Group 20195.svg" alt="arrow" onClick={() => setNumber(number + 3)} />
              }
            </>}
            <p className="container différence text-white py-3">{t('Portfolio.descriptionseo')}</p>
        </Table>
      </div >
    </>)
}

const WhatIs = styled.div`
  padding:30px 15% 30px 15% ;
  line-height: 28px;
  text-align: center;
  @media (max-width: 991px){
    padding: 60px 10% 30px 10% !important;
  }
  >h2{
    text-align: center;
    padding-bottom: 10px;
    @media (min-width:991px) {
      padding: 30px 0 10px 0;
    }
    @media (max-width:991px) {
      text-align: left;
    }
  }
  >p{
    padding-bottom: 10px;
  }
  .text{
    line-height: 28px;
    @media (max-width:991px) {
      text-align: left ;
    }
  }
  .text-ar{
    @media (max-width:991px) {
      text-align: right ;
    }
  }
`;


const Table = styled.div`
  padding:32px 0px 60px 0px;
  background-image: linear-gradient(to bottom right, #367ba7, #94cce5);
  text-align-last: center;  
  .listBotton{
    padding:30px 20px ;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 674px) {
    padding:30px 10% ;
    }
    >.btn{
      background-color:#ffffff29;
      border:2px solid white;
      font-family: PoppinsRegular;
      font-size: 18px;
      color:white;
      margin-right:15px;
      margin-bottom: 130px;
      @media (max-width:991px) {
        margin-bottom: 30px;
      }
      @media (max-width:500px) and (min-width: 431px){
        border-radius: 4px !important;
        width: 45%;
      }
      &:hover{
        background-color:white;
        color:#17406F ;
        box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
      }
      &:focus{
        background-color:white;
        color:#17406F;
      }
    }
    .active{
      border:2px solid white;
      background-color: white;
      color: #17406F;
      box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
    }
    .active + div {
      display:block!important;
      position:absolute;
      margin-top: 80px;
      @media (max-width:991px) {
        display:none!important;
      }
    }
  }
  >.loading{
    display: flex;
    justify-content: center;
  } 
  >.table{
    padding: 0 15%;
    display:flex ;
    flex-direction:row ;
    justify-content:center ;
    @media (max-width: 991px){
      padding: 0 10% !important;
    }
    @media (max-width: 1018px){
      justify-content: center;
    }
    >.link{
      text-align: center;
      padding: 0 11px 22px 11px;
      border: 0;
      width: 25%;
      min-width:17rem;
      @media (max-width:1555px) {
        width: 33%;
      }
      @media (min-width: 815px) and (max-width: 1187px) {
        width: 50%
      }
      @media (max-width: 814px) {
        width: 100%
      }
      >.card{
        border-radius: 15px;
        overflow: hidden;
        border: 4px solid white;
        @media (min-width: 814px) {
          height: 100%;
        }
        >.image{
          padding: 0;
          height: 250px;
          @media (max-width:2100px) {
            height: 200px;
          }
          @media (max-width:814px) {
            height: 350px;
          }
          @media (max-width:550px) {
            height: 300px;
          }
          @media (max-width:440px) {
            height: 250px;
          }
        }
        >.image >img{
          padding:0;
          width:100%;
          height: 100%;
          object-fit: cover;
          border-top:20px;
        } 
        >.card-body{
          height: auto;
          >.card-header {
            background-color: transparent;
            border: 0;
            padding: 0.2rem 0;
            h3{
            font-size: 14px !important;
            line-height: 24px !important;
            color: #20ACE3;
            padding-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-family: PoppinsRegular;
            font-weight: 600;
            margin-bottom: 0;
            }
          }
          >p{
            position: relative;
            >.url{
            font-weight: 600;
            }
            >span{
              color: #17406F;
            }
          }
        }
      }
    }
  }
  >.table{
    
  }
  >img{
    padding-top: 30px;
  }

  >.arrow{
    cursor: pointer;
  }
`;

export default withTranslation()(Portfolios)
