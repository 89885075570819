import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { WhatIs, Title } from "../../../../css/commun";
import { NosServices, Background } from "../../Home";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../../Variables";
import Pages from "../../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import Cf7FormWrapper from "../../Forms/Cf7FormWrapper";
import Popup from "../../../Popup";
import Standard from "./List/Standard";
import Netlinking from "./List/Netlinking";
import Blogging from "./List/Blogging";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";


const SubSeo = (props) => {
  const title = props.subprops.location.pathname
  const splitState = title.split('/')
  const { t } = props
  let handler = props.handler;
  const [pages, setPages] = useState([]);
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [existError, setExistError] = useState();
  const [buttonPopup, setButtonPopup] = useState(false);
  const [activePack, setActivePack] = useState(1)
  const [activePeriod, setActivePeriod] = useState(1)
  const [starter, setStarter] = useState(440);
  const [complet, setComplet] = useState(1140);
  const [expert, setExpert] = useState(1799)
  const initialValues = { yourwebsite: '', youremail: '' };
  const [formState, setFormState] = useState(initialValues)
  const validEmail = /^[a-zA-Z0-9._:$!%-à]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/;
  const validWebSite = /^(http(s?):\/\/)?(www\.)?[a-zA-Z0-9\.\-\_]+(\.[a-zA-Z]{2,3})+(\/[a-zA-Z0-9\_\-\s\.\/\?\%\#\&\=]*)?$/;
  const { formState: { errors } } = useForm();
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])


  const handleFieldChange = (field, e) => {
    !errors.yourwebsite && (errors.yourwebsite = {})
    !errors.youremail && (errors.youremail = {})

    setFormState({
      ...formState,
      [field]: e.target.value,
    })

    if (field === "yourwebsite") {
      if (e.target.value.length < 2) {
        errors.yourwebsite.message = "La longueur doit être de 2 ou plus"
        setExistError(false)
      } else if (!validWebSite.test(e.target.value)) {
        errors.yourwebsite.message = "Veuillez saisir valid URL"
        setExistError(false)
      } else {
        errors.yourwebsite = {}
      }
    }

    if (field === "youremail") {
      if (e.target.value.length < 5) {
        errors.youremail.message = "La longueur doit être de 5 ou plus"
        setExistError(false)
      } else if (!validEmail.test(e.target.value)) {
        errors.youremail.message = "L’adresse e-mail n’est pas valide."
        setExistError(false)
      } else {
        errors.youremail = {}
      }
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    !errors.yourwebsite && (errors.yourwebsite = {})
    !errors.youremail && (errors.youremail = {})
    const email = formState.youremail;

    var verifError = true;

    if (!formState.yourwebsite) {
      errors.yourwebsite.message = "Ce champ est Obligatoire"
      verifError = false
    } else if (formState.yourwebsite.length < 2) {
      errors.yourwebsite.message = "La longueur doit être de 2 ou plus"
      verifError = false
    } else if (!validWebSite.test(formState.yourwebsite)) {
      errors.yourwebsite.message = "Veuillez saisir valid URL"
      verifError = false
    }

    if (!formState.youremail) {
      errors.youremail.message = "Ce champ est Obligatoire"
      verifError = false
    } else if (formState.youremail.length < 5) {
      errors.youremail.message = "La longueur doit être de 5 ou plus"
      verifError = false
    } else if (!validEmail.test(email)) {
      errors.youremail.message = "L’adresse e-mail n’est pas valide."
      verifError = false
    }

    verifError && handler(e, formState);
    verifError && e.target.reset();
    setExistError(verifError);
  }

  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])


  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Title>
        <h1>{titre}</h1>
        <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Title>
      <WhatIs className={langue === "ar" && "alignRightMobile"}>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Professional SEO')}</h2>
        <p className="text">{t('Seo.An SEO perfectly mastered')}</p>
        <a className="link" href="#section">
          <button className={langue === "ar" ? "btn button button-ar joindre" : "btn button joindre"}><span className="pseudo-text">{t('Our formulas')}</span></button>
        </a>
      </WhatIs>

      <NosServices>
        <Services >
          <h2 className={langue === "ar" && "alignRightMobile"}>{t('Seo.How SEO team can help')}</h2>
          <p>{t('Seo.Oxton Digital\'s collaborators')}</p>
        </Services>
        <Services>
          <div>
            <img src="/images/seo/XMLID_1024_.svg" alt="XMLID_1024" /><br />
            <h3>{t('Seo.On site seo')}</h3>
            <label>{t('Seo.We optimize SEO')}</label>
            <p>{t('Seo.On site of your project')}</p>
          </div>
        </Services>
        <Services>
          <div>
            <img src="/images/seo/technical-support.svg" alt="technical-support" /><br />
            <h3>{t('Seo.Penalty Recovery')}</h3>
            <label>{t('Seo.You have been a victim')}</label>
            <p>{t('Seo.engine penalty')}</p>
          </div>
        </Services>
        <Services>
          <div>
            <img src="/images/seo/XMLID_753_.svg" alt="XMLID_753" /><br />
            <h3>{t('Seo.Technical SEO Audit')}</h3>
            <label>{t('Seo.A complete audit')}</label>
            <p>{t('Seo.personalized')}</p>
          </div>
        </Services>
        <Services>
          <div>
            <img src="/images/seo/Group 20215.svg" alt="Group 20215" /><br />
            <h3>{t('Seo.Infographics Content')}</h3>
            <label>{t('Seo.Our coupled SEO experts')}</label>
            <p>{t('Seo.with the latest in design')}</p>
          </div>
        </Services>
        <Services>
          <div>
            <img src="/images/seo/Mask Group 682.svg" alt="Mask Group 682" /><br />
            <h3>{t('Seo.Media Promotion')}</h3>
            <label>{t('Seo.powerful network')}</label>
            <p>{t('Seo.relevant sites')}</p>
          </div>
        </Services>
        <Services>
          <div>
            <img src="/images/seo/Group 20140.svg" alt="Group 20140" /><br />
            <h3>{t('Seo.Reporting & Analysis')}</h3>
            <label>{t('Seo.Our SEO experts use')}</label>
            <p>{t('Seo.state of the art')}</p>
          </div>
        </Services>
      </NosServices>
      <Background>
        <img src="/images/home/Plan de travail.jpg" alt="Plan de travail" />
      </Background>
      <AutreServices>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Other services')}</h2>
        <div className="row gy-3">
          <div className="col-md-4">
            <Link className="link" to='/prestation-de-service'>
              <div className="card">
                <div className="card-header">
                  <h3>{t('Subcontracting')}</h3>
                  <hr />
                </div>
                <p className="card-body">
                  {t('Seo.development_or_digital_needs')}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link className="link" to='/community-management'>
              <div className="card">
                <div className="card-header">
                  <h3>{t('CM')}</h3>
                  <hr />
                </div>
                <p className="card-body">
                  {t('Seo.spread_your_business')}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link className="link" to='/services-de-consultation'>
              <div className="card">
                <div className="card-header">
                  <h3>{t('Consulting_title')}</h3>
                  <hr />
                </div>
                <p className="card-body">
                  {t('Seo.Need_expert_advice')}
                </p>
              </div>
            </Link>
          </div>
        </div>

      </AutreServices>
      <SeoRéférencement>
        <h2 className={langue === "ar" && "alignRightMobile"}>
          {t('Seo.SEO_and_natural_referencing')}
        </h2>
        <div className="row">
          <div className="left col-md-6">
            {t('Seo.Your_website_is_attractive')}
          </div>
          <div className="left col-md-6">
            {t('Seo.Using_SEO_tools')}
          </div>
        </div>
        <div className="visibilité">
          <div className={first ? "solution solutionColor" : "solution"} onClick={() => first ? setFirst(false) : setFirst(true)}>
            <h3>{t('Seo.SEO_and_natural')}</h3>
            <span className={first ? "color" : ""}>{first ? <img src="/images/seo/up-arrow.svg" alt="up-arrow.svg" /> : <img src="/images/seo/down-arrow.svg" alt="down-arrow.svg" />} </span>
          </div>
          <p className={first ? "show" : "dont"}>
            {t('Seo.objective_of_SEO')}
          </p>
          <br className={first ? "dont" : "show"} />
          <div className={second ? "solution solutionColor" : "solution"} onClick={() => second ? setSecond(false) : setSecond(true)}>
            <h3>{t('Seo.A_lasting_impact')}</h3>
            <span className={second ? "color" : ""}>{second ? <img src="/images/seo/up-arrow.svg" alt="up-arrow.svg" /> : <img src="/images/seo/down-arrow.svg" alt="down-arrow.svg" />} </span>
          </div>
          <p className={second ? "show" : "dont"}>
            {t('Seo.A_good_referencing')}
          </p>
        </div>
      </SeoRéférencement>
      <Rapport>
        <h2 className={langue === "ar" && "alignRightMobile"}>
          {t('Seo.SEO_report')}
        </h2>
        <form className="form" onSubmit={handleFormSubmit} method="POST">
          <FormControl className="inputLeft">
            <InputLabel className={langue === "ar" && "label-ar"} style={{ color: 'white', marginTop: "-10px" }}>{t('Seo.website_URL')}</InputLabel>
            <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("yourwebsite", e)} autoComplete="off" />
            {errors.yourwebsite && (<small className="text-danger">{errors.yourwebsite.message}</small>)}
          </FormControl>
          <FormControl className={langue === "ar" ? "inputRight inputRight-ar" : "inputRight"}>
            <InputLabel  className={langue === "ar" && "label-ar"} style={{ color: '#fff', marginTop: "-10px" }}>{t('Enter_your_Email')}</InputLabel>
            <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("youremail", e)} autoComplete="off" />
            {errors.youremail && (<small className="text-danger">{errors.youremail.message}</small>)}
          </FormControl>
          <button type="submit" className={langue === "ar" ? "btn btn-primary button button-ar submit" : "btn btn-primary button submit"} onClick={() => setButtonPopup(true)}><span className="pseudo-text">{t('Subscribe')}</span></button>
          {existError &&
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} onBlur={() => setButtonPopup(false)}>
              <p>{t('Your_report_is_in_progress')}</p>
            </Popup>
          }
        </form>
      </Rapport>
      <Forfaits id="section">
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('List_of_packages')}</h2>
        <div className="ListButtonPack">
          <button className={activePack === 1 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePack(1); setStarter(activePeriod === 1 ? 440 : 380); setComplet(activePeriod === 1 ? 1140 : 950); setExpert(activePeriod === 1 ? 1799 : 1499); }}>{t('Standard_title')}</button>
          <button className={activePack === 2 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePack(2); setStarter(activePeriod === 1 ? 139 : 120); setComplet(activePeriod === 1 ? 239 : 209); setExpert(activePeriod === 1 ? 449 : 420); }}>{t('Netlinking_title')}</button>
          <button className={activePack === 3 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePack(3); setStarter(activePeriod === 1 ? 129 : 109); setComplet(activePeriod === 1 ? 229 : 180); setExpert(activePeriod === 1 ? 360 : 320); }}>{t('Blogging_title')}</button>
        </div>
        <div className="ListButtonPeriod">
          <button className={activePeriod === 1 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePeriod(1); setStarter(activePack === 1 ? 440 : activePack === 2 ? 139 : 129); setComplet(activePack === 1 ? 1140 : activePack === 2 ? 239 : 209); setExpert(activePack === 1 ? 1799 : activePack === 2 ? 449 : 360); }}>{t('SUBSCRIPTION')} 6 {t('MONTH')}</button>
          <button className={activePeriod === 2 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePeriod(2); setStarter(activePack === 1 ? 380 : activePack === 2 ? 120 : 109); setComplet(activePack === 1 ? 950 : activePack === 2 ? 229 : 180); setExpert(activePack === 1 ? 1499 : activePack === 2 ? 420 : 320); }}>{t('SUBSCRIPTION')} 12 {t('MONTH')}</button>
        </div>
        {activePack === 1 && <Standard starter={starter} complet={complet} expert={expert} langue={langue} />}
        {activePack === 2 && <Netlinking starter={starter} complet={complet} expert={expert} langue={langue} />}
        {activePack === 3 && <Blogging starter={starter} complet={complet} expert={expert} langue={langue} />}

      </Forfaits>
    </div>
  </>)
}

const Services = styled.div`
  width: 25%;
  min-width: 220px;
  height: 290px;
  padding: 30px;
  border: 1px solid #F1F7FF;
  z-index: 99;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content:flex-end ;
  overflow:hidden ;
  @media (max-width:1797px)  and (min-width: 1379px){
    height: 324px;
  }
  @media (max-width:1718px)  and (min-width: 1379px){
    height: 344px;
  }
  @media (max-width:1036px) and (min-width: 992px){
    height: 324px;
  }
  @media (max-width:898px) and (min-width: 874px){
    height: 324px;
  }
  @media (max-width:876px) and (min-width: 710px){
    height: 326px;
  }
  @media (max-width: 710px){
    height: 310px;
  }
  @media (max-width: 428px){
    height: 330px;
  }
  @media (max-width: 386px){
    height: 350px;
  }
  @media (max-width: 710px){
    width: 100%;
  }
  @media (max-width: 1378px) and (min-width: 711px){
    width: 50%;
  }
  &:hover{
      background-color: #F9FDFF; 
      }
  >div{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: transform 750ms;
    transition-duration: 750ms;
    &:hover{
      transform: translateY(-75px);
      background-color: #F9FDFF; 
      @media (max-width:1674px) and (min-width: 1379px){
        transform: translateY(-85px);
      }

      @media (max-width: 500px){
        transform: translateY(-90px);
      }
      >p{
        height: 0;
      }
    }
    >img{
      width: fit-content;
      height: 60px;
    }
    >h3{
      margin-bottom:10px ;
    }
    >p{
        position:relative ;
        height:0 ;
        left:0;
        margin:0 ;
        transition: transform 750ms;
        line-height:26px;
    }
  } 
  
  &:first-child{
    width: 50%;
    min-width: 220px;
    height: auto !important;
    padding-right: 5%;
    padding: 30px;
    display: flex;
    justify-content: space-around;
    background-color: white !important;
    font-size:16px;
  @media (max-width: 991px) {
    padding-bottom: 20px;
  }
   
  @media (max-width: 369px){
    height: 390px;
    background-color: red;
  } 

  @media (max-width: 447px) and (min-width: 370px){
    height: auto;
  }
  @media (max-width: 1378px){
    width: 100%;
    }
  .button{
    width: 200px;
    font-size: 16px;
    margin-top: 20px;
  @media (max-width: 365px){
    width: 190px;
    }
  @media (max-width: 991px){
    margin-left: 0;
    }
  }
}`;

export const AutreServices = styled.div`
  padding: 60px 15%;
  @media (max-width:991px) {
   padding: 60px 10%;
  }
  background-color: #EEF7FB;
  >h2{
    text-align:center;
    padding-bottom: 30px;
    margin-bottom: 0;
    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
  >.row{
    >.col-md-4{
      @media (max-width: 1250px) {
        width: 100%;
      }
      >.link{
        text-decoration: none;
        >a{
          color:red;
        }
        >.card{
          border-radius:15px;
          border: 0;
          background-color: transparent;
          padding:30px;
  
          >.card-header{
            background-color: transparent;
            border: 0;
            padding: 0;
            >hr{
              width:113px;
              color: #20ACE3;
              height: 2px;
            }
          }
          >.card-body{
            padding: 0;
            margin: 0;
            color: #17406F;;
          }
          :hover{
            background-color: white;
            box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
          }
        }
      }
    }
  }
`;

const SeoRéférencement = styled.div`
  padding: 60px 15%;
  @media (max-width:991px) {
   padding: 60px 10%;
  }
  >h2{
    text-align: center;
    padding-bottom: 30px;
  }
  >div{
    padding-top: 40px;
    @media (min-width: 991px) {
    >h2{
      text-align: center;
    }
    }
    >h2{
      padding-bottom: 30px;
    }
    >p{
      padding: 20px;
      margin-bottom: 10px;
      border: 1px solid #20ACE3;
      border-radius: 0 0 15px 15px;
    }
    >.show{
      display:block;
    }
    >.dont{
      display:none;
    }

    >.solution{
      background-color: #EEF7FB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 15px;
      cursor: pointer;
      padding: 15px;
      >h3{
        padding-right: 40px;
      }
      >span{
        font-size: 30px;
        font-weight:800;
      }
      >.color{
        color: #20ACE3;
      }
    }

    >.solutionColor{
      background-color: #20ACE3;
      border-radius: 15px 15px 0 0;
      >h3{
        color: white;
      }
    }
  }
`;

const Rapport = styled.div`
  padding: 60px 15% 70px;
  @media (max-width:991px) {
   padding: 60px 10% 70px;
  }
  @media (max-width:743px) {
   padding: 60px 10% 90px;
  }
  background-image: linear-gradient(to bottom right, #367ba7, #94cce5);


  >h2{
    text-align: center;
    color: white;
    padding-bottom: 40px;
  }

  >.form{

    >.inputLeft{
      width: 30%;
      margin-right: 4%;
      @media (max-width:743px) {
        width: 100%;
        margin-right: 0;
        padding-bottom: 20px;
      }
    }
  
    >.inputRight{
      width: 30%;
      margin-left: 4%;
      @media (max-width: 743px) {
        width: 100%;
        margin-left: 0;
        padding-bottom: 50px;
      }
    }
    >.inputRight-ar{
      margin-left: 0;
      margin-right: 4%;
    }
  
    >.inputLeft .input ,>.inputRight .input{
      border: 0;
      border-bottom:2px solid white;
      background-color: transparent;
      outline: none;
      color: white;
    }

    .text-danger{
      margin-top: 5px;
      height: 0;
    }
  
    input:-webkit-autofill,
    .my-class:-webkit-autofill {
      transition-delay: 9999999s;
    }
  
    >.submit{
      background-color: white;
      color: #20ACE3;
      padding: 10px 50px !important;
      float: right;
      @media (max-width: 743px) {
        float: left;
      }
    }
  }
`;

const Forfaits = styled.div`
  padding: 60px 15%;
  @media (max-width:991px) {
   padding: 60px 10%;
  }
  >h2{
    text-align: center;
    padding-bottom: 60px;
  }
  >.ListButtonPack{
    background-color: #20ACE3;
    margin-bottom: 40px ;
    border-radius: 15px;
    @media (max-width: 577px) {
      display: flex;
      flex-direction: column;
    }
    >.btn-light{
      width: 33.33%;
      color: white;
      background-color: transparent;
      border: 0;
      @media (max-width: 577px) {
      width: 100%;
      }
    }
    >.active{
      background-color: white;
      color: #20ACE3;
      font-weight: 600;
      border: 4px solid #20ACE3;
    }
    >.btn-light.active:focus{
      box-shadow: none;
    }
    >.btn-light:focus-visible{
      outline: none;
      border: transparent;
    }
    >.btn-light:focus{
      box-shadow: none;
      border: 4px solid #20ACE3;
    }
    .btn-light:active{
      border: 4px solid #20ACE3;
    }

  }
  >.ListButtonPeriod{
    background-color: transparent;
    border-color: #20ACE3;
    margin-bottom: 40px ;
    border-radius: 15px;
    text-align: center;
    @media (max-width: 577px) {
      display: flex;
      flex-direction: column;
    }
    >.btn-light{
      color: #20ACE3;
      background-color: transparent;
      padding-right: 50px !important;
      padding-left: 50px !important;
      border: 2px solid transparent;
      @media (max-width: 676px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
      }
      @media (max-width: 626px) {
        padding-right: 30px !important;
        padding-left: 30px !important;
      }
      @media (max-width: 577px) {
        width: 100%;
      }
    }
    >.active{
      background-color: white;
      color: #20ACE3;
      font-weight: 600;
      border: 2px solid #20ACE3;
    }
    >.btn-light.active:focus{
      box-shadow: none;
    }
    >.btn-light:focus-visible{
      outline: none;
      border: transparent;
    }
    >.btn-light:focus{
      box-shadow: none;
      border: 2px solid #20ACE3;
    }
    .btn-light:active{
      border: 2px solid #20ACE3;
    }

  }
  >.row {
    justify-content: center;
    .col-md-4 {
      @media (max-width: 1051px) {
      width: 50%;
    }
    @media (max-width: 663px) {
      width: 100%;
    }
    justify-content: center;
    
    .card{
      box-shadow: 0px 0px 6px rgba(32, 172, 227, .16);
      padding: 30px;
      height: 100%;
      border-radius: 15px;
      >ul{
        list-style: none;
        padding-left: 0;
        >li{
          line-height: 28px;
          font-size: 14px;
          >h3{
            font-style: italic;
            font-weight: 600;
            line-height: 28px !important;
            margin-bottom: 0;
            font-size: 14px !important;
          }
          >.blue{
            color :#20ACE3;
          }
        }
        >div{
          background-color: #EEF7FB;
          padding-top: 20px;
          margin-bottom: 20px;
          border-radius: 15px;
          font-weight: 600;
          color :#20ACE3;
          text-align: center;
          >.euroextendedbold{
            color: #17406F;
            font-family: EurostileExtendedBold;
          }
          .number{
            font-size: 42px;
            >sup{
              font-size: 16px;
              position: relative;
              top: -25px;
              margin-left: -12px;
            }
          }
        }
      }
      >.ul-ar{
        padding-right: 0;
      }
      >div{
        display: flex;
        flex-direction: column;
        align-items: center;
        place-content: flex-end;
        height: 100%;

        >p{
          padding: 10px ;
          text-align: center;
          border-radius: 15px;
          background-color: #EEF7FB;
          font-weight: bold;
          >span{
            color: #20ACE3;
          }
        } 
        >.button{
          padding: 10px 20px !important;
          color: white;
          width: 150px;
          border-radius:10px !important;
        }
      }
    }
  }
}
`;


export default withTranslation()(SubSeo)