import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {HelmetProvider} from 'react-helmet-async'
const rootElement = document.getElementById("root");

ReactDOM.render(
  <HelmetProvider>
    
    <App/>
  </HelmetProvider>
, rootElement)