import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { Title } from "../../../css/commun";
import Cf7FormWrapper from "./Cf7FormWrapper";
import { useForm } from "react-hook-form";
import { URL_Back, URL_Dev } from "../Variables";
import Maps from "../../../Model/Maps";
import Pages from "../../../Model/Pages";
import Contacter from "../../../Model/Contacter";
import { Helmet } from "react-helmet-async";
import Popup from "../../Popup";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import { Link } from 'react-router-dom';
const SubContact = (props) => {

  const title = props.subprops.location.pathname
  const splitState = title.split('/')
  const { t } = props
  let handler = props.handler;
  let isloading = props.isloading;
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState([]);
  const [contacter, setContacter] = useState([]);
  const [existError, setExistError] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [voirPlus, setVoirPlus] = useState(true);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])


  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
    fetch(`${URL_Back}/wp-json/wp/v2/maps?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/contacter?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setContacter(result)
        },
      )

  }, [])

  let maps = [];
  items.map(element =>
    maps.push(new Maps(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered))
  )

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  let contacterEmail = []
  let contacterTel = []
  contacter.map(element =>
    element.title.rendered === "Email" &&
    contacterEmail.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element.image)))
  contacter.map(element =>
    element.title.rendered === "Telephone" &&
    contacterTel.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
  )

  const initialValues = { yourname: '', youremail: '', yoursubject: '' };
  const [formState, setFormState] = useState(initialValues)
  const letters = /^[A-Za-z0-9._,.:;*$"'#?!^[{}()\]\\\-\+=@é àçèâêîû/ô._\s-]+$/;
  const validEmail = /^[a-zA-Z0-9._:$!%-à]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/;
  const { formState: { errors } } = useForm();

  const handleFieldChange = (field, e) => {
    !errors.yourname && (errors.yourname = {})
    !errors.yoursubject && (errors.yoursubject = {})
    !errors.youremail && (errors.youremail = {})

    setFormState({
      ...formState,
      [field]: e.target.value,
    })

    if (field === "yourname") {
      if (e.target.value.length < 2) {
        errors.yourname.message = "La longueur doit être de 2 ou plus"
        setExistError(false)
      } else if (!e.target.value.match(letters)) {
        errors.yourname.message = "Veuillez saisir uniquement des lettres"
        setExistError(false)
      } else {
        errors.yourname = {}
      }
    }

    if (field === "yoursubject") {
      if (e.target.value.length < 5) {
        errors.yoursubject.message = "La longueur doit être de 5 ou plus"
        setExistError(false)
      } else {
        errors.yoursubject = {}
      }
    }

    if (field === "youremail") {
      if (e.target.value.length < 5) {
        errors.youremail.message = "La longueur doit être de 5 ou plus"
        setExistError(false)
      } else if (!validEmail.test(e.target.value)) {
        errors.youremail.message = "L’adresse e-mail n’est pas valide."
        setExistError(false)
      } else {
        errors.youremail = {}
      }
    }
  }

  
  const handleFormSubmit = (e) => {
    e.preventDefault();

    !errors.yourname && (errors.yourname = {})
    !errors.yoursubject && (errors.yoursubject = {})
    !errors.youremail && (errors.youremail = {})

    var verifError = true;

    if (!formState.yourname) {
      errors.yourname.message = "Ce champ est Obligatoire";
      verifError = false
    } else if (formState.yourname.length < 2) {
      errors.yourname.message = "La longueur doit être de 2 ou plus"
      verifError = false
    }

    if (!formState.yoursubject) {
      errors.yoursubject.message = "Ce champ est Obligatoire"
      verifError = false
    } else if (formState.yoursubject.length < 5) {
      errors.yoursubject.message = "La longueur doit être de 5 ou plus"
      verifError = false
    } else if (!formState.yoursubject.match(letters)) {
      errors.yoursubject.message = "Veuillez saisir uniquement des lettres"
      verifError = false
    }

    if (!formState.youremail) {
      errors.youremail.message = "Ce champ est Obligatoire"
      verifError = false
    } else if (formState.youremail.length < 5) {
      errors.youremail.message = "La longueur doit être de 5 ou plus"
      verifError = false
    } else if (!validEmail.test(formState.youremail)) {
      errors.youremail.message = "L’adresse e-mail n’est pas valide."
      verifError = false
    }

    verifError && handler(e, formState)
    verifError && e.target.reset()
    setExistError(verifError);
  }

  return (
  <>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Title>
        <h1>{titre}</h1>
        <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <a className="retour" href="/">{t('Home_link')}</a> / <span>{titre}</span>
        </p>
      </Title>
      <Form >
        <h3 className={langue === "ar" && "alignRightMobile"}>{t('Contact.message')}</h3>
        <p className={langue === "ar" && "alignRightMobile"}>
          {t('Contact.start')} &nbsp;
          <span className={voirPlus ? "hide" : ""}>
            &nbsp;{t('Contact.looking')}
          </span>
          <span onClick={() => voirPlus ? setVoirPlus(false) : setVoirPlus(true)} className="pseudo-text">{voirPlus ? t('more') : t('less')}</span>
        </p>
        <form className="from" onSubmit={handleFormSubmit} method="POST">
          <FormControl className={langue === "ar" ? "inputleft inputleft-ar" : "inputleft"}>
            <InputLabel className={langue === "ar" && "label-ar"} style={{ color: 'white', marginTop: "-10px" }}>{t('Contact.name')}</InputLabel>
            <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("yourname", e)} />
            {errors.yourname && (<small className="text-danger">{errors.yourname.message}</small>)}
          </FormControl>
          <FormControl className={langue === "ar" ? "inputRight inputRight-ar" : "inputRight"}>
            <InputLabel className={langue === "ar" && "label-ar"} style={{ color: '#fff', marginTop: "-10px" }}>{t('Contact.Email')}</InputLabel>
            <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("youremail", e)} autoComplete="off" />
            {errors.youremail && (<small className="text-danger">{errors.youremail.message}</small>)}
          </FormControl>
          <div className="Project">
            <FormControl fullWidth className={langue === "ar" ? "fullWidth fullWidth-ar" : "fullWidth"}>
              <InputLabel className={langue === "ar" && "label-ar"} style={{ color: '#fff', marginTop: "-10px" }}>{t('Contact.project')}</InputLabel>
              <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("yoursubject", e)} autoComplete="off" />
              {errors.yoursubject && (<small className="text-danger">{errors.yoursubject.message}</small>)}
            </FormControl>
          </div>
          <button type="submit" className={langue === "ar" ? "btn btn-primary button button-ar submit" : "btn btn-primary button submit"} onClick={() => setButtonPopup(true)}><span className="pseudo-text">{t('Contact.Send')}</span></button>
          {existError &&
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} onBlur={() => setButtonPopup(false)}>
              <p>{t('Contact.Thank')}</p>
            </Popup>
          }
          {/* <Link to={langue === "fr" ? "/fr/devis" : langue === "en" ?  "/en/quotation" : "/ar/التسعير"} className="links">
          Demander un devis
          </Link> */}
        </form>
      </Form>
      <Map>
        {maps.map((item, key) => (
          <div className="mapouter" key={key}>
            <div className="gmap_canvas maps" dangerouslySetInnerHTML={{ __html: item.content }} />
            <br />
          </div>
        ))}
      </Map>
      <Details>
        <div>
          <img src="/images/Contact/send.png" alt="send" /><br />
          {contacterEmail.map(item => (
            <a key={item.id} className="aLink" href={`mailto:${item.content.slice(4, -5)}`}>{item.content.slice(4, -5)}<br /></a>
          ))}
        </div>
        <div>
          <img src="/images/Contact/espace-reserve-a-la-carte (1).png" alt="espace-reserve-a-la-carte" /><br />
          <span>{t('Contact.paris')}</span> : {t('Contact.location_paris')} <br />
          <span>{t('Contact.tunis')}</span> : {t('Contact.location_tunis')}
        </div>

        <div className="listOfDrapo">
          <img src="/images/Contact/telephone (2).png" alt="telephone" /><br />
          <ul>
            {contacterTel.map((item, key) => (
              <li key={key} >
                <img className="drapo" src={item.image} alt={item.title} />
                <a className="aLink" href={`tel:${item.content.slice(4, -5)}`}><bdi>{item.content.slice(4, -5)}</bdi></a><br />
              </li>
            ))}
          </ul>
        </div>
      </Details>
      
    </div>
  </>)
}

const Map = styled.div`
  padding: 30px 4%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
    @media (max-width:991px) {
      flex-direction: column;
    }
  >div{
    width: 49%;
    @media (max-width:991px) {
      width: 100%;
      >.mapFrance{
        margin-top: 30px;
      }
    }
  }

  @media (max-width:670px){
    padding: 30px 9% !important;
  }
  @media (max-width:860px) and (min-width: 670px){
    padding: 30px 8% !important;
  }
  @media (max-width:991px) and (min-width:860px){
    padding: 30px 7% !important;
  }
  @media (max-width:1250px) and (min-width:992px){
    padding: 30px 6% !important;
  }
  @media (max-width:1490px) and (min-width:1250px){
    padding: 30px 5% !important;
  }
`;

const Details = styled.div`
  padding:10px 15% 60px 15%;
  display:flex ;
  font-weight: 600;
  @media (max-width: 1150px) {
    padding:10px 10% 60px 10%;
    
  }
  @media (max-width: 1000px){
    flex-direction: column;
    justify-content: center;
    }
  >div >ul{
    list-style-type: none;
    display: inline-block;
    >li{
      text-align: initial !important;
      
    }
  }
  >div{
    width: 25%;
    text-align:center ;
    line-height: 30px;
    @media (max-width: 1000px){
    width: 100% !important;
    }
    &:nth-child(2){
      width: 50%;
    >span{
      color: #20ACE3;
    }
    } 
    >img{
      padding:30px;
    }
  }
`;

const Form = styled.div`
  padding:30px 15% 50px 15%;
  color:white ;
  height: auto;
  background-image: linear-gradient(to right, #367ba7, #95cce5);
  @media (max-width: 991px){
    padding:30px 15%;
  }

  >h3{
    padding: 35px 0 30px 0;
    text-align:center ;
    font-size: 32px !important;
    color:white;
    @media (max-width:991px) {
      text-align: left;
      font-size: 24px !important;
    }
  }

  >p {
    text-align: center;
    @media (max-width: 991px) {
      text-align: left;
    }
  }

  >p >span.pseudo-text{
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }

  .inputleft{
    width: 46%;
    margin-right: 4%;
    @media (max-width:676px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .inputleft-ar{
    margin-right: 0;
    margin-left: 4%;
  }

  .inputRight{
    width: 46%;
    margin-left: 4%;
    @media (max-width:676px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .inputRight{
    margin-right: 4%;
    margin-left: 0;
  }

  .fullWidth{
    margin: 0 2% 0 0;
  }
  .fullWidth-ar{
    margin: 0 0 0 2%;
  }
  .hide{
    display: none;
  }

  >.from{
    line-height: 100px;
  }
  .input{
    border: 0;
    border-bottom:2px solid white;
    background-color: transparent;
    outline: none;
    color: white;
  }

  input:-webkit-autofill,
  .my-class:-webkit-autofill {
    transition-delay: 9999999s;
  }

  .submit{
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color:#20ACE3;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 10px 50px !important;
  }

  .text-danger{
    position: absolute;
    height: 0px;
    top:15px;
    font-size: 16px;
    font-weight: 600;
    color: #c51414b3 !important;
  }

  >form span.merci{
    display: flex;
    place-content: center;
    color: #036203;
    z-index:9999;
  }
`;

export default withTranslation()(SubContact);
