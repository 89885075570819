import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Title, WhatIs } from "../../../css/commun";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Consulting = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        <Title>
          <h1>{titre}</h1>
          <p>
            <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
            <Link className="retour" to={`/${langue}`}>{t('Home_link')}</Link> / <span>{titre}</span>
          </p>
        </Title>
        <WhatIs className={langue === "ar" && "alignRightMobile"}>
          <h2 className={langue === "ar" && "alignRightMobile"}>
            {t('Consulting.strategy')}
          </h2>
          <p>
            {t('Consulting.Looking_for_help')} <br />
            {t('Consulting.starting')}
          </p>
        </WhatIs>
        <Equipe>
          <div className="row gy-4">
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <img src="/images/consulting/Group 20132.svg" alt="Group 20132.svg" />
                  <h3 className={langue === "ar" && "h3-ar"}>{t('Consulting.sales_team')}</h3>
                </div>
                <p className="card-body">
                  {t('Consulting.Our_consulting')}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <img src="/images/consulting/Group 20131.svg" alt="Group 20132.svg" />
                  <h3>{t('Consulting.project_managers')}</h3>
                </div>
                <p className="card-body">
                  {t('Consulting.experienced_project')}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <img src="/images/consulting/support.svg" alt="Group 20132.svg" />
                  <h3>{t('Consulting.support')}</h3>
                </div>
                <p className="card-body">
                  {t('Consulting.consulting_services')}
                </p>
              </div>
            </div>
          </div>
        </Equipe>
        <From>
          <div className="left">
            <h3>{t('Consulting.accomplishment')}</h3>
            <p>{t('Consulting.hire_us')}</p>
            <br /><a className="link" href="https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/" target="_blank"><button className={langue === "ar" ? "btn btn-light button button-ar benificie" : "btn btn-light button benificie"}><span className="pseudo-text">{t('appointment')}</span></button></a>
          </div>
          <div className={langue === "ar" ? "right right-ar alignRightMobile" : "right"}>
            <div>
              <img className="image1" src="/images/consulting/Mask Group 629.png" alt="Mask Group 629" />
              <img className="image2" src="/images/consulting/Mask Group 772.png" alt="Mask Group 772" />
            </div>
          </div>
        </From>
        <Fromm>
          <div className={langue === "ar" ? "right right-ar alignRightMobile" : "right"}>
            <div>
              <img className="image1" src="/images/consulting/Mask Group 630.png" alt="Mask Group 630" />
              <img className="image2" src="/images/consulting/Mask Group 773.png" alt="Mask Group 773" />
            </div>
          </div>

          <div className="left">
            <h3>{t('Consulting.Working_time')}</h3>
            <p>{t('Consulting.work_with_you')}</p>
            <br /><a className="link" href="https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/" target="_blank"><button className={langue === "ar" ? "btn btn-light button button-ar benificie" : "btn btn-light button benificie"}><span className="pseudo-text">{t('appointment')}</span></button></a>

          </div>
        </Fromm>
        <From>
          <div className="left">
            <h3>{t('Consulting.availability')}</h3>
            <p>{t('Consulting.contact_us')}</p>
            <br /><a className="link" href="https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/" target="_blank"><button className={langue === "ar" ? "btn btn-light button button-ar benificie" : "btn btn-light button benificie"}><span className="pseudo-text">{t('appointment')}</span></button></a>
          </div>
          <div className={langue === "ar" ? "right right-ar alignRightMobile" : "right"}>
            <div>
              <img className="image1" src="/images/consulting/Mask Group 631.png" alt="Mask Group 631" />
              <img className="image2" src="/images/consulting/Mask Group 774.png" alt="Mask Group 774" />
            </div>
          </div>
        </From>
        <Besoins>
          <h2 className={langue === "ar" && "alignRightMobile"}>{t('Consulting.Support')}</h2>
          <div className="row gy-4">
            <div className="col-md-4">
              <div className="card">
                <p>"There are many variations of passages of Lorem Ipsum available, but the majority have suffered  "</p>
                <div className="card-body">
                  <img src="/images/consulting/Mask Group 359.png" alt="Mask Group 359" />
                  <span>Nom et prénom<p>Co-Founder</p></span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <p>"There are many variations of passages of Lorem Ipsum available, but the majority have suffered  "</p>
                <div className="card-body">
                  <img src="/images/consulting/Mask Group 360.png" alt="Mask Group 359" />
                  <span>Nom et prénom<p>Co-Founder</p></span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <p>"There are many variations of passages of Lorem Ipsum available, but the majority have suffered"</p>
                <div className="card-body">
                  <img src="/images/consulting/Mask Group 361.png" alt="Mask Group 359" />
                  <span>Nom et prénom<p>Co-Founder</p></span>
                </div>
              </div>
            </div>
          </div>
        </Besoins>
      </div>
    </>
  )
}


const Equipe = styled.div`
padding:10px 15% 60px 15%;
@media (max-width:991px) {
  padding:10px 10% 60px 10% ;
}
@media (max-width:500px) {
  padding:30px 10% 60px 10% ;
}
>.row{
  @media (max-width: 766px){
    justify-content: center;
  }
  >div{
    flex-basis:33%;
    @media (max-width:680px) {
      flex-basis:100%;
    }
    @media (max-width:1086px) and (min-width:681px) {
      flex-basis:50%;
    }
    >.card{
      padding:30px 30px 0 30px;
      border: 0;
      border-radius: 15px;
      height: 100%;
      box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
      >.card-header{
        background-color: transparent;
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        padding: 0;
        >h3{
          line-height: 30px !important;
          @media (max-width: 2096px) {
            height: 90px;
          }
          @media (max-width: 1590px) {
            height: 120px;
          }
          @media (max-width: 1247px) {
            height: 150px;
          }
          @media (max-width: 1086px) {
            height: auto;
          }
        }
        >h3{
          height: auto;
          @media (max-width: 1377px) and (min-width: 1087px) {
            height: 55px;
          }
        }
        >img{
          width: 50px;
          height: 60px;
          margin-bottom: 20px;
        }
      }
      >p{
        padding:15px 0;
        font-weight:normal;
      }
    }
  }  
}
`;

const From = styled.div`
  margin:0 15% 30px 15% ;
  @media (max-width:991px) {
    margin:0 10% 30px 10% ;
  }
  display: flex;
  flex-wrap: wrap;
  background-color: #ecf6fb;
  border-radius: 15px;
  font-size: 16px;
  @media (max-width: 870px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 1324px){
    justify-content: center;
  }
  @media (max-width: 413px){
  margin:0 10% 30px 10% ;
  }
  >.left{
    width: 50%;
    min-width: 13rem;
    padding:30px;
    @media (max-width: 870px){
      width:100% !important;
      padding-top: 0;
    }
    >p{
      margin-bottom: 0;
    }
    .benificie{
      background-color: #20ACE3;
      color:white;
      border-radius: 15px;
      margin-left: 0;
      font-weight: 400;
    }
  }
  .right{
    padding: 30px;
    >div{
      overflow: hidden;
      border-radius: 15px;
      height: 100%;
      .image2{
        display: none;
      }
      @media (max-width:870px) {
        .image1{
          display: none;
        }
        .image2{
          display: block;
        }
      }
      >img{
        @media (max-width:1574px) and (min-width: 1304px){
          position:relative;
          height: 100%;
        } 
        @media (max-width:1303px) and (min-width: 871px){
          position:relative;
          height: 100%;
          right:180px
        }  
        @media (max-width: 870px){
          width: 100%;
        }
      }
    }
    width: 50%;
    min-width: 13rem;
    @media (max-width: 870px){
      width:100% !important;
    }
  }
  .right-ar >div >img{
    @media (max-width:1303px) and (min-width: 871px){
      position:relative;
      height: 100%;
      right:-90px
    } 
  }
`;

const Fromm = styled.div`
  margin:0 15% 30px 15% ;
  @media (max-width:991px) {
    margin:0 10% 30px 10% ;
  }
  display: flex;
  flex-wrap: wrap;
  background-color: #ecf6fb;
  border-radius: 15px;
  @media (max-width: 1324px){
    justify-content: center;
  }
  @media (max-width: 413px){
  margin:0 10% 30px 10% ;
  }
  >.left{
    width: 50%;
    min-width: 13rem;
    padding:30px;
    @media (max-width: 870px){
      width:100% !important;
      padding-top: 0;
    }
    >p{
      margin-bottom: 0;
    }
    .benificie{
      background-color: #20ACE3;
      color:white;
      border-radius: 15px;
      margin-left: 0;
      font-weight: 400;
    }
  }
  .right{
    padding: 30px;
    >div{
      overflow: hidden;
      border-radius: 15px;
      height: 100%;
      >img{
        @media (max-width:1574px) and (min-width: 1304px){
          position:relative;
          height: 100%;
        } 
        @media (max-width:1303px) and (min-width: 871px){
          position:relative;
          height: 100%;
          right:180px
        }  
        @media (max-width: 870px){
          width: 100%;
        }
      }
      .image2{
        display: none;
      }

      @media (max-width:870) {
        .image1{
          display: none;
        }
        .image2{
          display: block;
        }
      }
    }
    width: 50%;
    min-width: 13rem;
    @media (max-width: 870px){
      width:100% !important;
    }
  }
  .right-ar >div >img{
    @media (max-width:1303px) and (min-width: 871px){
      position:relative;
      height: 100%;
      right:-90px
    } 
  }
`;

const Besoins = styled.div`
  padding:30px 15% 60px 15% ;
  @media (max-width:991px) {
    padding: 0 10% 60px;
  }
  >h2{
    text-align: center;
    padding-top:10px;
    padding-bottom: 60px; 
    @media (max-width:991px) {
      padding: 0 0 20px;
    }
  }
>.row{
  padding: 0;
  @media (max-width: 766px){
    justify-content: center;
  }
  >div{
    flex-basis: 33%;
    @media (max-width: 766px) and (min-width: 504px){
      flex-basis: 50%;
    }
    @media (max-width: 503px){
      flex-basis: 100%;
      
    }
    >div{
      padding:30px 30px 5px 30px;
      border: 0;
      border-radius: 15px;
      box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
      >div{
        padding:10px 0;
        display: flex;
        >img{
            width: 38px;
            height: 38px;
            border-radius: 50%;
            border: 1px solid #20ACE3;
            padding: 2px;
            margin-right: 10px;
        }
        >span {
          font-family: EurostileExtendedBold;
          p{
            color:#20ACE3;
            font-family: PoppinsRegular;
          }
        }    
      }
    }
  }
  
  }
`;


export default withTranslation()(Consulting)
