import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { Title } from "../../../../css/commun";
import { URL_Back, URL_Dev } from "../../Variables";
import { Link } from 'react-router-dom';
import Pages from "../../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Cgvmsi = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const [pages, setPages] = useState([]);
  const { t } = props  
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'").replace("&#8211;", "-") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        {/* {splitState[1] === "fr" &&
          <> */}
            <Title>
              <h1>{titre}</h1>
              <p>
                <img className={langue === "ar" ? "img-accueil-ar" : ""} src="/images/accueil.png" alt="accueil" />
                <Link className="retour" to="/">{t('Home_link')}</Link> / {page[0] !== undefined && <span>{titre}</span>}
              </p>
            </Title>
            <Body>
              <p>Il est conclut entre les parties,</p>
              <p>SIB INTERACTIVE GROUP, société par actions simplifiée est en activité depuis 1 an. Domiciliée à ERMONT (95120), elle est spécialisée dans le secteur d'activité de la programmation informatique. Societe.com recense 1 établissement et le dernier événement notable de cette entreprise date du 20-01-2020.</p>
              <p>et le client, d'autre part :</p>
              <h3>ARTICLE 1 : OBJET</h3>
              <p>Le présent contrat a pour objet de définir les interventions opérées par Oxton Digital sur le site Internet du client dans le cadre de sa mission de maintenance préventive, corrective et évolutive.</p>
              <h3>ARTICLE 2 : DEFINITION DES PRESTATIONS</h3>
              <h4>MAINTENANCE PRÉVENTIVE</h4>
              <p>La maintenance technique préventive consiste à gérer les mises à jour et patches de sécurité du noyau du CMS Joomla, et de ses extensions open source (composants, modules et plugins Joomla). Les opérations de mise à jour de Joomla et de ses extensions doivent être distinguées avec les procédures de migration vers de nouvelles versions de Joomla (3.0, etc.) qui ne sont pas prises en compte dans le cadre de ce contrat, et qui devront faire l'objet d'une étude préalable et d'un bon de commande spécifique.</p>
              <h4>MAINTENANCE CORRECTIVE</h4>
              <p>La maintenance corrective consiste à assister le client ou à intervenir directement sur son site internet pour l'aider dans la résolution d'éventuels bogues et autres dysfonctionnements.</p>
              <h4>MAINTENANCE ÉVOLUTIVE</h4>
              <p>La maintenance évolutive consiste à assister le client dans l'évolution quotidienne de son site internet :</p>
              <ul>
                <li>Mise à jour d'articles ou d'items liés à un composant Joomla en cas d'absence des administrateurs du site</li>
                <li>Intégration HTML basique : ajout ou modifications d'images (exceptées celles liées à l'architecture graphique du site), de vidéos, assistance à la mise en page d'un article, etc. ;</li>
                <li>Modifications simple apportées à l’arborescence du site</li>
                <li>tout autre développement mineur n'impliquant pas une intervention supérieure à 2 heures de travail / homme).</li>
                <li>L'ajout ou la modification importante de fonctionnalités occasionnant une intervention supérieure à deux heures de travail / homme devront faire l'objet de devis spécifiques n'entrant pas dans le cadre du présent contrat de maintenance.
                  <p>Il s'agit d'un mode de maintenance réputé en « best effort », où le client devra s'efforcer d'effectuer lui-même la tâche ou la résolution du problème technique auquel il est confronté et ne s'adresser à Oxton Digital qu'en cas d'impossibilité technique, humaine ou matérielle. Dans ce cas, il pourra faire appel à Oxton Digital pour réaliser cette tâche ou résoudre ce problème.</p>
                </li>
              </ul>
              <p>Oxton Digital s'engage à mettre en œuvre tous les moyens à sa disposition pour mener à bien sa mission, les jours ouvrables et pendant les heures de bureau (9h-12h et 14h-18h30), dans la limite de ses obligations de moyens et à l'exception d’événements relevants de cas de force majeure, d'opérations de maintenance et d’entretien effectuée sur le serveur par l'hébergeur du site internet, ou par la rupture du service imputable à l'hébergeur ou au fournisseur d'accès à Internet. Les dispositions de garantie de temps de rétablissement (GTR) seront détaillées dans le bon de commande en accord avec le client.</p>
              <p>En dehors des horaires de bureau, un n° de portable sera communiqué à le client pour lui permettre de joindre un responsable de Oxton Digital pour qu'il intervienne, dans la mesure du possible, en cas d'extrême urgence (site hacké, information erronée et « sensible », etc.)</p>
              <h3>ARTICLE 3 : DETECTION DES PROBLEMES</h3>
              <p>Tout problème éventuel devra être notifié à Oxton Digital par mail ou par téléphone.</p>
              <p>Si les investigations menées par Oxton Digital lors de la notification d’un problème par le client établissent que ce problème est imputable au client, Oxton Digital se réserve le droit de facturer son intervention, à partir du moment où celle-ci excède deux heures de travail/homme.</p>
              <h3>ARTICLE 4 : DROITS, RESPONSABILITE ET OBLIGATIONS D'OXTON DIGITAL<br /></h3>
              <p>Les interventions éventuelles de Oxton Digital auront lieu, hors cas d'extrême urgence, du lundi au vendredi entre 9h et 12h et 14h et 18h30. Oxton Digital s'engage à mener à bien sa mission conformément aux dispositions énoncées dans les articles précédents et ce en conformité avec les règles de l'art.</p>
              <p>Le fonctionnement du service pourra être interrompu pour permettre à Oxton Digital d’effectuer les travaux de maintenance imposés par sa mission. Oxton Digital s'engage cependant, lorsque les conditions le permettent, à réaliser ces interventions pendant les heures les moins pénalisantes pour le client. En cas d’inaccessibilité au serveur due à des dysfonctionnements techniques de son ressort, Oxton Digital s’engage à rétablir dans les 8 heures le bon fonctionnement du service.</p>
              <p>En cas de non respect par le client de ses obligations, Oxton Digital se réserve le droit d’interrompre ou de suspendre sans préavis tout ou partie de la prestation mis en cause, et pourra à tout moment prendre toutes les dispositions nécessaires pour faire cesser un trouble manifeste causé à Oxton Digital ou à un tiers. En cas de violation manifeste grave, Oxton Digital se réserve le droit à tout moment de résilier de plein droit le contrat par lettre recommandée avec accusé de réception. Cette suspension ou résiliation de la prestation service pour ces motifs ne donnera droit au versement d’aucun dédommagement.</p>
              <p>En aucun cas, la responsabilité de Oxton Digital ne pourra être recherchée lorsqu’il y a : faute, négligence, omission ou défaillance du client, non respect des conseils donnés, force majeure, événements ou incidents indépendants de la volonté de Oxton Digital, faute, négligence ou omission d’un tiers sur lequel Oxton Digital n’a aucun pouvoir de contrôle et de surveillance.</p>
              <p>Dans le cas où la responsabilité de Oxton Digital serait retenue, le client ne pourrait prétendre à aucun préjudice indirect tel que la perte de clientèle, de chiffre d’affaire ou de bénéfice. Par ailleurs, le montant des indemnités est expressément limité au montant payé par le client au titre des deux derniers mois d’abonnement à la date d’origine du préjudice.</p>
              <p>Le client reconnaît que Oxton Digital est pleinement autorisé à citer son nom en tant que référence commerciale.</p>
              <h3>ARTICLE 5 : DROITS, OBLIGATIONS ET RESPONSABILITE DU CLIENT</h3>
              <p>Le client devra fournir à Oxton Digital toutes les informations et données indispensables à la bonne conduite de sa mission : documentation technique du dispositif existant, accès illimités au serveur d'hébergement et à l'interface d'administration, codes ftp, accès mysql...</p>
              <p>Lorsque le client confie à Oxton Digital le soin d'actualiser son site internet, de mettre à jour des contenus, rédactionnels, illustratifs ou iconographiques, le client devra fournir ces contenus sur support informatique soit par e-mail, soit par le biais d'un serveur ftp mis à sa disposition. Le client est seul responsable de la validité des informations et des fichiers transmis à Oxton Digital dans le cadre de sa mission.</p>
              <p>Le client déclare accepter les caractéristiques et les limites du réseau Internet. Les données sur Internet circulant sur des réseaux hétérogènes aux capacités techniques diverses, la fiabilité des transmissions ne peut être garantie.</p>
              <p>Le client s’engage à respecter l’ensemble de la réglementation applicable à la diffusion d’informations sur Internet. La transmission ou le stockage d’informations ou de données en violation avec les lois et règlements en vigueur est interdite, cela inclus, sans y être limité, les documents portant atteinte aux droits d’auteurs, les informations qui présenteraient un caractère menaçant, choquant, violent, diffamatoire, xénophobe, discriminatoire ou qui seraient contraires à l’ordre public ou aux bonnes mœurs.</p>
              <p>Le client s’engage à respecter les règles en vigueur sur Internet «Netiquette». En particulier, l’envoi de courrier électronique non sollicité «Spam» à partir d’un serveur ou d’une adresse email maintenue par le client est interdit.</p>
              <p>Le client est responsable de l’usage de son nom d’utilisateur et de son mot de passe, toute connexion effectuée en utilisant ce nom d’utilisateur et mot de passe sera réputée l’avoir été par le client ou un de ses préposés. Le client s’engage à prévenir immédiatement Oxton Digital en cas de perte ou de vol de ces données.</p>
              <p>Le client est le seul responsable des dommages et préjudices directs ou indirects, matériels ou immatériels du fait de son utilisation des prestations de Oxton Digital et s’engage à indemniser Oxton Digital contre toute demande, réclamation et/ou condamnation à des dommages et intérêts, dont Oxton Digital pourrait être l’objet dès lors que celles-ci auraient pour cause l’utilisation par le client du service de maintenance.</p>
              <p>Dans l'hypothèse où l'hébergement de la solution concernant les présentes conditions générales de vente de maintenance n'est pas confié à Oxton Digital, celle-ci ne saurait être tenue responsable de problèmes liés à l'environnement de l'hébergement, aux technologies utilisées sur le-dit serveur d'hébergement, ou à toute autre limite ou facteur bloquant qui seront en l'occurrence réputés comme ne pouvant lui être imputés.</p>
              <p>Le client s’engage à informer immédiatement Oxton Digital de toute modification concernant sa situation: changement d’adresse, de numéro de téléphone ou de coordonnées bancaires.</p>
              <p><strong>A propos de Joomla!</strong></p>
              <p>Le nom Joomla!® et son logo sont utilisés sous license limitée d'Open Source Matters, propriétaire mondial de ces marques commerciales. Oxton Digital n'est pas affiliée ni soutenue par Open Source Matters ou le projet Joomla!®</p>
              <p>Joomla est fourni "en l'état" au moment de la création du site du client, dans la dernière version disponible en téléchargement sur le site joomla.org au moment de la signature du bon de commande par le client. Hors dispositions contraires, le contrat de maintenance conclu entre Oxton Digital et son client ne prévoit en aucun la migration vers des versions supérieures de Joomla! (exemple : de Joomla 1.5 vers 2.5 ou de Joomla 2.5 vers 3.X) mais seulement la mise à jour de la dernière version de Joomla dans la même série (exemple : de Joomla 2.5.6 vers Joomla 2.5.7 ou de Joomla 3.3.6 vers Joomla 3.4). Oxton Digital avertira le client lorsque sa version de Joomla! arrive en fin de vie et celui-ci devra, pour des raisons de sécurité et de pérennité du dispositif technique du client, se rapprocher de Oxton Digital afin qu'elle établisse un bon de commande pour la migration vers une version supérieure de Joomla.</p>
              <p>Le client reconnaît par la présente que, dès lors qu'il a été informé de la nécessité de procéder à la migration de son dispositif vers la dernière version à jour de Joomla, il ne pourra tenir Oxton Digital pour responsable des conséquences de l'obsolescence de l'architecture technique de son site internet. De même, si la sécurité ou l'intégrité de son site venait à être corrompue du fait de l'obsolescence de son socle technique, le client reconnaît que Oxton Digital sera susceptible de lui facturer les frais d'intervention liés à la remise en état de son site.</p>
              <h3>ARTICLE 6 : L’ABONNEMENT</h3>
              <p>Les prix liés à l’abonnement sont mentionnés sur le ou les bon(s) de commande annexé(s) au contrat.</p>
              <p>Le contrat sera conclu pour une durée minimale de 12 (douze) mois, puis renouvelé d'année en année par tacite reconduction. Chacune des parties pourra résilier le contrat à chaque échéance par lettre recommandée avec accusé de réception en respectant un préavis de 1 (un) mois.</p>
              <p>Les factures sont payables sous 30 (trente) jours, net, sans escompte. Le client pourra, au choix, régler à Oxton Digital le montant de sa prestation en utilisant les moyens de paiement suivants :</p>
              <ul>
                <li>par chèque libellé à l'ordre de SIB INTERACTIVE GROUP, 214 RUE DU GENERAL LECLERC 95120 ERMONT</li>
                <li>par virement bancaire sur le compte  SIB INTERACTIVE GROUP, les informations de paiement se trouve sur votre facture<br /></li>
              </ul>
              <p>La date de mise en service de la prestation souscrite constitue la date de prise d’effet du contrat et le point de départ de la facturation.</p>
              <p>Le prix de la période payée d’avance est garanti pour la période concernée. Oxton Digital se réserve le droit de modifier ses tarifs à tout moment en informant le client au moins 30 (trente) jours avant la date effective. Le client peut contester toute hausse des tarifs et résilier le contrat par lettre recommandée avec accusé de réception dans un délai de 9 (neuf) jours ouvrés à partir de la date d’entrée en vigueur de ces nouveaux tarifs. A défaut, ces nouveaux tarifs seront réputés acceptés de façon irrévocable et seront appliqués à la prochaine facturation.</p>
              <p>En cas de non paiement dans un délai de 15 (quinze) jours à compter de l’envoi d’une lettre recommandée avec avis de réception notifiant le manquement en cause, Oxton Digital se réserve le droit d’interrompre les prestations et ce, jusqu’au paiement complet des sommes dues avec une majoration de 10% (dix pour cent) par mois civil de retard.</p>
              <p>L’interruption de la prestation vaudra résiliation de plein droit du contrat en cas de non régularisation sous 15 (quinze) jours après seconde mise en demeure qui pourra prendre la forme d’un courrier électronique ou d’une télécopie et d’un courrier postal avec accusé de réception.</p>
              <p>Le client devra alors régler les sommes non réglées jusqu’au terme de la période contractuelle et non pas jusqu’à la date de résiliation augmentés de 250 € HT (deux cent cinquante euros HT) pour les frais de dossier. En aucun cas le client ne pourra demander une quelconque indemnité à Oxton Digital du fait de l’interruption de ses prestations suite à un incident de paiement. En cas de refus ou incident bancaire (dont compte bloqué, compte débiteur ou interdit bancaire) sur les prélèvements automatiques, le client sera redevable d'une pénalité de 50 € par prélèvement refusé.</p>
              <h3>ARTICLE 7 : CONFIDENTIALITE</h3>
              <p>Chacune des parties s’engage à conserver confidentiels les informations et documents concernant l’autre partie de quelque nature qu’ils soient et auxquels elles auraient pu avoir accès au cours de l’exécution des prestations commandées.</p>
              <p>Les deux parties prendront vis à vis de leur personnel toutes les mesures nécessaires pour assurer, sous leur responsabilité, le secret et la confidentialité de toutes les informations et documents définis ci-dessus.</p>
              <h3>ARTICLE 8 : DOMICILIATION</h3>
              <p>Les parties élisent domicile au lieu de leur siège social.</p>
              <p>Les notifications se feront par e-mail avec accusé de réception en règle générale, sauf en cas de litige ou elles seront effectuées par lettre recommandée avec accusé de réception.</p>
              <h3>ARTICLE 9 : LEGISLATION</h3>
              <p>Le futur contrat sera régi par la loi française.</p>
              <p>Il en est ainsi pour les règles de fond comme pour les règles de forme.</p>
              <h3>ARTICLE 10 : AUTRES CLAUSES</h3>
              <p>Aucune des deux parties ne sera tenue pour responsable vis a vis de l'autre de la non exécution ou des retards dans l'exécution d'une obligation du présent contrat qui seraient dus au fait de l'autre partie consécutivement à la survenance d'un cas de force majeure habituellement reconnu par la jurisprudence.</p>
              <p>Le cas de force majeure suspend les obligations nées du présent contrat pendant toute la durée de son existence. Toutefois si le cas de force majeure devait être d'une durée supérieure à 60 (soixante) jours ouvrés, il ouvrirait droit à la résiliation de plein droit du présent contrat par l'un ou l'autre des parties, 8 (huit) jours ouvrés après l'envoi d'une lettre recommandée avec avis de réception, notifiant cette décision.</p>
              <p>Si une des clauses devait être nulle au regard d'une règle de droit ou d'une loi en vigueur, elle serait réputée non écrite mais n'entraînerait pas la nullité des autres clauses du contrat.</p>
              <p>« EN CAS DE LITIGE, COMPETENCE EXPRESSE EST ATTRIBUEE AU TRIBUNAL DE COMMERCE DE PONTOISE (95), NONOBSTANT PLURALITE DE DEFENDEURS APPEL EN GARANTIE, MEME POUR LES PROCEDURES D’URGENCE OU POUR LES PROCEDURES CONSERVATOIRES, EN REFERE OU PAR REQUETE ».214 RUE DU GENERAL LECLERC 95120 ERMONT</p>
            </Body>
          {/* </>
        } */}
      </div>
    </>)
}

const Body = styled.div`
  padding:25px 15% 30px 15%;
  @media (max-width:991px) {
    padding:25px 10% 30px 10%;
  }
  h3{
    font-size: 20px !important;
  }
  strong{
    font-size: 20px !important;
  }
  
`

export default withTranslation()(Cgvmsi)