import { Link } from 'react-router-dom';


export const ListPortfolio = (props) => {

  const portfolio = props.portfolio;
  const number = props.number;
  const langue = props.langue;

  return (
    <div className="d-flex flex-wrap table">
      {portfolio.map((item, i) => (i < number) && (
        <Link to={
          langue === "fr"
          ? { pathname: `/fr/details-de-portfolio/${(item.slug.replace(/[^a-zA-Z0-9.-]/g, ""))}`, state: item }
          : langue === "en"
            ? { pathname: `/en/details-of-portfolio/${(item.slug.replace(/[^a-zA-Z0-9.-]/g, ""))}`, state: item }
            : { pathname: `/ar/تفاصيل-الملف/${(item.slug.replace(/[^a-zA-Z0-9.-]/g, ""))}`, state: item }
        }
          className="text-black text-decoration-none link" key={item.id} >
          <div className="card">
            <div className='image'>
              {item.image !== undefined && <img src={item.image} alt={item.title} />}
            </div>
            <div className="card-body">
              <div className='card-header'>
                <h3 className="card-title" dangerouslySetInnerHTML={{ __html: item.title }} />
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}