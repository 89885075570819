import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { Title } from "../../../../css/commun";
import { URL_Back, URL_Dev } from "../../Variables";
import { Link } from 'react-router-dom';
import Pages from "../../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Cgvps = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const [pages, setPages] = useState([]);
  const { t } = props
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])


  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        {/* {splitState[1] === "fr" &&
          <> */}
            <Title>
              <h1>{titre}</h1>
              <p>
                <img className={langue === "ar" ? "img-accueil-ar" : ""} src="/images/accueil.png" alt="accueil" />
                <Link className="retour" to="/">{t('Home_link')}</Link> / {page[0] !== undefined && <span>{titre}</span>}
              </p>
            </Title>
            <Body>
              <p> relatives aux prestations de services internet de l'Agence Digitale</p>
              <p> <strong>Article 1 - Identification de la société</strong> <br />
                SARL AGENCE DIGITALE<br />Siège : 181, avenue de Verdun, Immeuble les Cèdres, 83340 Le Cannet des Maures<br />
                SIRET: 488 211 426 00033 <br />
                E-mail : info[at]agence-digitale.com<br />
                Site Internet : www.agence-digitale.com</p>
              <p><strong>Article 2 - Objet</strong><br />
                L’AGENCE DIGITALE est une société ayant pour activité le conseil et la mise en oeuvre de projets dans le domaine des nouvelles technologies de l'information et de la communication. Le présent document a pour objet de définir les droits et devoirs de l’Agence Digitale et du Client dans la réalisation des services liés aux métiers de l’AGENCE DIGITALE : étude, conseil, organisation, conception, réalisation, promotion, animation, administration, formation, suivi de projets dans le domaine des nouvelles technologies de l'information (internet, intranet, etc.).</p>
              <p><strong>Article 3 - Conditions de vente</strong><br />
                Tout achat de prestations commercialisées par L'AGENCE DIGITALE entraîne l’adhésion entière et sans réserve des conditions générales de vente. Aucune condition particulière ne peut, sauf acceptation formelle écrite de notre part, prévaloir contre nos conditions générales de ventes. Toute condition contraire posée par le client sera donc inopposable à défaut d’acceptation expresse et écrite de notre part. Le fait que nous ne nous prévalions pas, à quelque moment que ce soit, de l’une quelconque des présentes conditions générales de vente ne peut être interprétée comme valant renonciation à se prévaloir ultérieurement de l’une quelconque desdites conditions.</p>
              <p><strong>Article 4 – PROTECTION DES DONNEES</strong></p><p><em>
                NOTE IMPORTANTE CONCERNANT LE RGPD</em></p>
              <p>L'Agence Digitale ne peut être tenue responsable de l'interprétation et de l'utilisation qui seront faites par le client de la réglementation RGPD. Elle n'est tenue qu'à une obligation de moyen pour assister son client dans la mise en conformité de son site internet. Sa responsabilité ne pourra être engagée dans l'hypothèse où :</p><ul><li><p>le client utilise des services externes dont l'Agence Digitale n'a pas eu connaissance ou dont elle n'est pas à l'origine de l'utilisation et/ou de l'intégration</p></li><li><p>les services externes concernés ne permettent pas la suppression ou la modification des traceurs de suivi</p></li><li><p>
                l'un des fournisseurs de l'Agence Digitale n'a pas respecté ses propres obligations vis à vis du RGPD</p></li><li><p>le nouveau règlement sur la protection des données venait à évoluer</p></li></ul>
              <p>Le client reconnaît donc par la présente que la prestation d'assistance à la conformité RGPD proposée par l'Agence Digitale ne le soustrait en aucun cas de ses propres obligations liées au RGPD, et qu'en tout état de cause, elle ne concerne que la mise en conformité de son site internet et pas les autres obligations auquel il est soumis. Il devra notamment s'assurer avec son service juridique et /ou son conseil de la pertinence et de la validité de l'intervention de l'Agence Digitale par rapport aux informations dont elle dispose.</p>
              <p><strong>Article 5 - Responsabilité liée à la prestation</strong><br />
                5.1 - La responsabilité de L'AGENCE DIGITALE est limitée au site internet et à son contenu dont elle a le contrôle direct.<br />
                5.2 - L'AGENCE DIGITALE ne saurait être responsable des liaisons de tous types, assurées par d’autres prestataires.<br />
                5.3 - L'AGENCE DIGITALE ne saurait être responsable des dégâts accidentels ou volontaires causés par des tiers au client du fait de sa connexion à Internet.<br />
                5.4 - L'AGENCE DIGITALE ne saurait être responsable, dans le cadre d'un site géré par le client ou L'AGENCE DIGITALE, de la perte, du vol ou de la diffusion des codes d'accès client.<br />
                5.5 - Pour toute commande de création et d’hébergement, L'AGENCE DIGITALE se réserve le droit de refuser texte, illustration et en général tous contenus qui sont contraires aux bonnes moeurs, ou en infraction avec la législation.<br />
                5.6 - L'AGENCE DIGITALE se réserve le droit d'insérer une mention sur le site du client stipulant qu'elle en est l'auteur.</p>
              <p><strong>Article 6 - Obligations du client</strong>
                <br />6.1 - Dans le cas ou le client fournit à L'AGENCE DIGITALE les informations nécessaires à l'exécution de la commande, ces informations devront être transmises selon les spécifications de L'AGENCE DIGITALE. La réalisation du site web et/ou des prestations associées commandé ne sera réalisée dans un délai fixé à la commande qu'à compter du moment de la réception de toutes les informations nécessaires à cette réalisation.<br />
                6.2 - Dans le cas ou L'AGENCE DIGITALE assure le contenu rédactionnel tels que textes, photos et vidéo en complément ou non des informations fournies par le client, l'article 6.3 s'applique également.<br />
                6.3 - L'ensemble des informations ayant été, préalablement à leur diffusion, sélectionné par le client, L'AGENCE DIGITALE ne saurait être en aucun cas rendue responsable de leur contenu. Lors de l'acceptation de la commande, nous présumons que le client est en possession du droit d'auteur ou de reproduction, et assume toute responsabilité pour les préjudices résultant d'une violation des droits d'auteur ou de reproduction appartenant à un tiers.<br />
                6.4 - L'AGENCE DIGITALE ne saurait être tenue pour responsable du non fonctionnement de tout ou partie du site Internet dans le cas d'un hébergement non assuré par ses soins.<br />
                6.5 - Le client sait que L'AGENCE DIGITALE reste seul propriétaire des droits de reproduction de ses créations internet, résultant notamment de la propriété littéraire et artistique. Toute représentation ou reproduction même partielle faite sans autorisation est illicite.<br />
                6.6 - Dans le cadre de la vente de composants ou d'extensions Joomla, le client est informé qu'il n'est en aucun cas autorisé à revendre, distribuer ou céder, sous aucune forme que ce soit, les fichiers ou le code source acquis auprès de l'Agence Digitale.</p>
              <p><strong>Article 7 - Prix des services, facturation, règlement</strong><br />
                7.1 - Les prix des prestations - création de site Internet, achat de nom de domaine, référencement - proposés sont ceux mentionnés dans le devis ; ils s'entendent hors taxes et sont payables en euros selon la modalité suivante : 30% à la commande et 70% à la livraison pour un montant inférieur à 1000,00 €, 50% à la commande pour tout autre montant, sauf autre accord précisé sur le devis. Les tarifs sont garantis sans variation à la signature de la commande, sauf cas de force majeure, comme p^récisé dans le acdre de l'article 18.<br />
                7.2 - La signature du devis par le client vaut reconnaissance du versement de l'acompte.</p>
              <p><strong>Article 8 - Propriété</strong><br />
                Conformément à la loi du 25 janvier 1985, le transfert de propriété des prestations faisant l'objet de la facture ne sera effectif qu'au moment du paiement intégral du prix mentionné.</p>
              <p><strong>Article 9 - Hébergement et gestion du site</strong></p>
              <p>9.1 - Prestations</p>
              <p>Les prestations d'hébergement client proposés sont fournis par un prestataire partenaire tiers.</p>
              <p>L'offre d'hébergement est liée obligatoirement à une ou plusieurs prestations de services de notre part. Le transfert des DNS sur le serveur de notre partenaire est possible si une ou plusieurs prestations de services de notre part y sont liées. Dans tous les cas, le client pourra prendre connaissance des informations administratives et techniques nécessaires à l'accès à son/ses hébergements.</p>
              <p>9.2 - Responsabilité<br />9.2.1 - Dans le cas de la défaillance de ce partenaire, notre société s’engage à trouver et vous proposer toutes les solutions possibles pour y remédier, mais L'AGENCE DIGITALE se dégage de toute responsabilité quant aux conséquences de cette défaillance dont les causes ne sauraient être directement imputables à notre société.</p>
              <p>9.2.2 - En aucun cas, L'AGENCE DIGITALE ne saurait voir sa responsabilité engagée à la suite de toute action ou recours de tiers, notamment du fait : d'informations, d'images, de sons, de textes, de vidéos contraires aux législations et réglementations en vigueur, contenus et/ou diffusés sur le ou les sites du client ; de produits défectueux que le client a vendu par l'intermédiaire de son ou ses sites ; de la violation des droits de propriété intellectuelle relatifs aux oeuvres diffusées, en intégralité ou partiellement, sur le ou les sites du client ; de la suspension et/ou de la résiliation des comptes, notamment à la suite du non règlement des sommes dues à la conservation des nom de domaines.</p>
              <p>9.2.3 - L'AGENCE DIGITALE ne peut être redevable d'aucune taxation ou autres frais en relation avec les achats faits à partir du site client. Le client accepte de prendre la pleine responsabilité des taxes et frais de toutes natures associés aux produits vendus.</p>
              <p>9.2.4 - Du fait des caractéristiques et limites de l'Internet, L'AGENCE DIGITALE ne saurait voir sa responsabilité engagée pour, notamment les difficultés d'accès au site hébergé du fait de la saturation des réseaux à certaines périodes ; la contamination par virus des données et/ou logiciels du client ; les intrusions malveillantes de tiers sur le site du client ; le mauvais fonctionnement des équipements ou le non savoir-faire du client, de problèmes liés au réseau téléphonique ou à internet et/ou en cas de force majeure ; les détournements éventuels des mots de passe, codes confidentiels, et plus généralement de toute information à caractère sensible pour le client.</p>
              <p>9.2.5 - Utilisation du compte<br />Le compte client est strictement personnel, il est accéssible par un login et un mot de passe confidentiel. Le client est responsable de l'usage de son login et de son mot de passe, toute connexion effectuée en utilisant ces login et mot de passe sera réputée l'avoir été par le client. L'AGENCE DIGITALE ne sera en aucun cas responsable de la perte, du vol ou de la diffusion des login et mot de passe.</p>
              <p>9.2.6 - Prix des services, facturation, règlement<br />Les prix des loyers et prestations associées sont ceux mentionnés dans le devis ; ils s'entendent hors taxes, sont payables en euros, d'avance et à réception de la facture. Les prix des loyers payés d'avance sont garantis pour la période concernée. L'AGENCE DIGITALE se réserve la faculté de modifier ses prix à tout moment sous réserve d'un préavis d'un (1) mois. En cas de refus de cette modification, le client devra, par lettre recommandée avec avis de réception, solliciter la résiliation du présent contrat avant la fin du délai de un (1) mois visé à l'article 9.3. A défaut, les nouveaux tarifs seront réputés acceptés de façon irrévocable et seront appliqués à la facturation qui suivra l'expiration du délai précité.</p>
              <p>9.3 - Durée, renouvellement et dénonciation du contrat, suspension<br />9.3.1 - Le contrat est conclu pour une durée annuelle qui commence à courir à compter de la date de sa conclusion. Le contrat est renouvelé par tacite reconduction, pour une durée identique à celle fixée lors de sa précédente conclusion, selon les tarifs et conditions de L'AGENCE DIGITALE à la date de son renouvellement, sauf dénonciation par l'une des parties dans les conditions de l'article 9.3.3.</p>
              <p>9.3.2 - Le client sera prévenu par courriel, au plus tôt trois mois et au plus tard un mois avant le terme de la période autorisant le rejet de la reconduction</p>
              <p>9.3.3 - Toute dénonciation devra être faite par lettre recommandée avec avis de réception adressée à : L'AGENCE DIGITALE – 181, avenue de Verdun, Immeuble les Cèdres, 83340 Le Cannet des Maures au plus tard un (1) mois avant la date anniversaire du contrat.</p>
              <p>9.3.4 - En tenant compte de l'article 9.3.2, L'AGENCE DIGITALE peut cesser sa prestation à tout moment par ce contrat sans pénalité, si le client ne suit pas les termes de ce contrat, ceci comprenant l'impayé, L'AGENCE DIGITALE se réserve le droit de faire payer des frais de rétablissement. Les comptes dont les factures sont impayées au delà de 7 jours pourront voir leur service interrompu, et tous les fichiers et email du compte pourront être rendus inutilisables. Cette interruption de service ne relève pas le client de l'obligation de paiement des factures en cours, seul une demande écrite d'arrêt du compte sera prise en compte.</p>
              <p><strong>Article 10 - Nom de domaine</strong><br />10.1 - Prestations<br />L'AGENCE DIGITALE offre de procéder à l'enregistrement de noms de domaine avec les extensions .com, .net et .org, ainsi que .fr et autres extensions disponibles en France afin de permettre à toutes personnes physiques ou morales de s'assurer de la propriété du ou des noms de leur choix. L'AGENCE DIGITALE s'engage à tout mettre en oeuvre auprès des organismes compétents pour procéder à l'enregistrement du nom de domaine choisi mais ne souscrit à ce titre aucune obligation de résultat. L'AGENCE DIGITALE mettra en ligne sous ce nom de domaine une page type, non susceptible de personnalisation, faisant apparaître une mention comportant le nom de domaine.</p>
              <p>10.2 - Disponibilité et enregistrement d'un nom de domaine<br />Du fait des délais qui peuvent séparer la recherche de l'enregistrement du nom de domaine envisagé, l'indication de la disponibilité de ce nom ne constitue nullement la garantie de pouvoir effectivement procéder à son enregistrement. La disponibilité d'un nom de domaine et son enregistrement ne sauraient constituer une garantie contre toute revendication de tiers sur tout ou partie de ce nom.</p>
              <p>10.3 - Acceptation préalable du client des règles administratives et techniques de nommage, ainsi que des règles de résolution des conflits</p>
              <p>10.3.1 - Chaque demande d'enregistrement implique l'acceptation préalable et sans réserve des règles administratives et techniques de nommage ainsi que des règles de résolution des conflits pouvant survenir entre le propriétaire du nom de domaine et tout tiers revendiquant des droits sur tout ou partie de ce nom.</p>
              <p>10.3.2 - Il appartient au client de fournir à L'AGENCE DIGITALE les justificatifs éventuellement requis pour l'enregistrement considéré, tel un Kbis ou l'identifiant au répertoire INSEE pour un nom du niveau .fr ou encore le certificat d'enregistrement à l'INPI pour un nom de niveau .tm.fr.</p>
              <p>10.4 - Prix du service, paiement<br />Les prix en vigueur sont ceux mentionnés sur le bon de commande signé et retourné par le client. Les prix s'entendent en hors taxes et sont payables en euros à réception de la facture.</p>
              <p>10.5 - Enregistrement de la commande<br />L'enregistrement du nom de domaine du client ne sera pris en considération qu'à réception par L'AGENCE DIGITALE du bon de commande dûment rempli et signé par le client accompagné du règlement et des justificatifs nécessaires.</p>
              <p>10.6 - Prise d'effet de l'enregistrement<br />L'enregistrement du nom de domaine n'est effectif qu'à compter de la mise à jour des bases de données des organismes concernés (Afnic, Internic) et du temps de propagation des DNS.</p>
              <p>10.7 - Responsabilité du fait de l'enregistrement d'un nom de domaine</p>
              <p>10.7.1 - Le client est seul responsable du choix du nom de domaine dont il a sollicité et obtenu l'enregistrement</p>
              <p>10.7.2 - En aucun cas, L'AGENCE DIGITALE ne saurait être tenue pour responsable à quelque titre que ce soit de recours, amiables ou contentieux, dont le client pourrait faire l'objet consécutivement à l'enregistrement d'un ou plusieurs noms de domaine.</p>
              <p>10.7.3 - Le client s'engage à garantir L'AGENCE DIGITALE de toutes condamnations qui pourraient être prononcées à son encontre du fait de l'enregistrement d'un ou plusieurs nom de domaine.</p>
              <p><strong>Article 11 - Commande</strong></p>
              <p>Nos conditions générales de vente s'appliquent à toutes nos ventes ou/et prestations de services. En signant le document dénommé bon de commande, devis ou proposition commerciale, le client accepte sans réserves nos conditions de vente et renonce à toute application de ses éventuelles conditions générales d'achat. Les commandes de nos clients sont fermes après délai de retractation légale ou sauf avis contraire de notre part sous huitaine à compter de la réception du bon de commande.</p>
              <p>Nos devis et bons de commande sont réputés valables pour une durée de un mois (30 jours).</p>
              <p><strong>Article 12 - Délais</strong></p>
              <p>Les délais de livraison indiqués ne sont valables que dans la limite des capacités techniques et humaines de L'AGENCE DIGITALE à répondre aux commandes. Le client ne peut exiger de L'AGENCE DIGITALE ni la livraison de la commande à la date prévue, ni indemnité de quelque nature que ce soit. Le client reste redevable de la somme prévue.</p>
              <p><strong>Article 13 - Retard de paiement</strong></p>
              <p>Tout incident et/ou retard de paiement à l'échéance entraînera l'application d'une pénalité de retard, prévue par la loi 92-1442 du 31 décembr/e 1992, art. 3-1 al. 3, égale à une fois et demie le taux d'intérêt légal sur les sommes dûes, ainsi que la facturation de l'ensemble des frais financiers, de dossier, de mise en demeure et de recouvrement correspondants, sans préjudice de la suspension et/ou de la résiliation du présent contrat.</p>
              <p>En cas de manquement par l'une des parties aux obligations des présentes clauses, non réparé dans un délai de huit jours à compter de la lettre recommandée avec accusé de réception, notifiant les manquements, adressée par l'autre partie, cette dernière pourra faire valoir la résiliation du contrat sous réserve de tous les dommages et intérêts auxquels elle pourra prétendre.</p>
              <p>En cas de règlement amiable, de procédures de redressement ou de liquidation judiciaire, de suspension provisoire des poursuites, de faillite ou de procédures similaires, le présent contrat sera résilié automatiquement, sans notification, à compter de la décision du Tribunal compétent.</p>
              <p><strong>Article 14 - Taxes</strong></p>
              <p>L'AGENCE DIGITALE se réserve le droit de répercuter, sans délai, toute nouvelle taxe ou toute augmentation de taux des taxes existantes.</p>
              <p><strong>Article 15 - Obligation d'information</strong><br />Le client s'engage à informer, par écrit, L'AGENCE DIGITALE de toute modification concernant sa situation (notamment changement d'adresse, etc.).</p>
              <p><strong>Article 16 - Confidentialité</strong><br />L'AGENCE DIGITALE est tenue au respect de la confidentialité sur l'ensemble des opérations qu'elle réalise pour le compte du client. L'AGENCE DIGITALE s'interdit tout usage personnel des données de base, des fichiers et des résultats des traitements, sans l'autorisation expresse du client. D'une manière générale, les documents ou informations confiés par le client ainsi que les états et les documents provenant de leur traitement par L'AGENCE DIGITALE sont traités sous la plus stricte confidentialité.</p>
              <p><strong>Article 17 - Informatique et Liberté</strong><br />Le client pourra exercer son droit individuel d'accès et de rectification auprès de L'AGENCE DIGITALE, conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978, pour l'ensemble des informations communiquées dans le cadre du bon de commande ou de la convention pour la formation.</p>
              <p>Le client fait son affaire de toute déclaration auprès de la Commission Nationale Informatique et Liberté (CNIL) relative à l'utilisation de son site Internet sauf offre de prestation entrant dans ce cadre.</p>
              <p><strong>Article 18 - Force Majeure</strong><br />Ont pour effet de suspendre nos obligations contractuelles les cas fortuits ou de force majeurs telles que : le vol ou la destruction de l'outil de production, les grèves totales ou partielles entravant la bonne marche de notre entreprise ou celle d'un de nos fournisseurs, sous-traitants ou transporteurs, ainsi que l'interruption des transports, de la fourniture d'énergie, de matières premières ou de pièces détachées.</p>
              <p><strong>Article 19 - Loi applicable et Attribution de juridiction</strong><br />De convention expresse, les commandes de prestations sont régies par le droit français. En cas de litige, tant avec nos fournisseurs qu'avec nos clients, attribution de compétence exclusive est faite au Tribunal de Commerce de Draguignan (83).</p>
              <p><strong>Article 20 - Dispositions diverses</strong><br />Si l'une quelconque des clauses des présentes conditions générales s'avère non valable ou inopposable en vertu d'une loi ou d'un règlement ou à la suite d'une décision exécutoire d'une juridiction ou d'une autorité administrative compétente, cette clause sera réputée non écrite et le reste du contrat conservera son plein effet.</p>
            </Body>
          {/* </>
        } */}
      </div>
    </>)
}

const Body = styled.div`
  padding:25px 15% 30px 15%;
  @media (max-width:991px) {
    padding:25px 10% 30px 10%;
  }
  strong{
    font-size: 20px !important;
  }
`

export default withTranslation()(Cgvps)