import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { URL_Back } from "../../Variables";
import Portfolio from "../../../../Model/Portfolio";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";


const Progress = (props) => {
  const { t } = props

  const [items, setItems] = useState([]);
  const [rating, setRating] = useState([]);

  useEffect(() => {

    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )

    document.getElementsByClassName("wp-google-rating")[0] != undefined &&
      setRating(document.getElementsByClassName("wp-google-rating")[0].innerHTML)

  })


  let portfolio = [];
  items.map(element =>
    portfolio.push(new Portfolio(element.id, element.title.rendered))
  )

  return (
    <Progresss>
      <ul className="offre">
        <li>
          <div className="card">
            <img src="/images/entreprise/Group 19268.svg" alt="Group 20132.svg" />
            <h3>1.354</h3>
            <div className="card-body">
              {t('Entreprise.Active users')}
            </div>
          </div>
        </li>
        <li>
          <div className="card">
            <img src="/images/entreprise/Group 19265.svg" alt="Group 20132.svg" />
            <h3>{parseFloat(rating)}/5</h3>
            <div className="card-body">
              {t('Entreprise.feedback from our customers')}
            </div>
          </div>
        </li>
        <li>
          <div className="card">
            <img src="/images/entreprise/Group 19266.svg" alt="Group 20132.svg" />
            <h3>{(portfolio.length/3).toFixed(0)}</h3>
            <div className="card-body">
              {t('Entreprise.Completed projects')}
            </div>
          </div>
        </li>
      </ul>
    </Progresss>
  )
}

const Progresss = styled.div`
margin:30px 15% 70px;
@media (max-width:991px) {
  margin:50px 10% 0px 10%;
}
@media (max-width:500px) {
  margin:50px 10% 40px 10%;
}
>.offre{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size:16px;
    list-style-type: none;
    padding: 0;
    @media (max-width: 500px){
      }
    >li{
      min-width: 11.6rem;
      &:nth-child(2){
        margin: 0 15px; ;
      }
      >.card{
        border: 0;
        >img{
          width: 65px;
          height: 60px;
          margin-bottom: 20px;
        }
        >.card-body{
            padding:0;
            font-size: 16px;
          }
        }
        &:nth-child(1){
          @media (max-width:500px) {
            margin-right: 15px;
          }
        }
        &:nth-child(2){
          @media (max-width:525px) {
            margin: 0;
          }
          @media (max-width:482px) {
            padding-top: 37px;
            margin: 0;
          }
        }
        &:nth-child(3){
          @media (max-width:757px) {
            padding-top: 40px;
            margin-right: 15px;
          }
        }
    }
  
  }
`;

export default withTranslation()(Progress)